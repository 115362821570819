import { youtubePrefix } from '../constants/youtube';

export const YOUTUBE_DOMAIN = 'youtube.com';
export const YOUTUBE_SHORT_DOMAIN = 'youtu.be';

// Youtube via iFrames does not support watch?v= urls, needs embed/ url
export const getYoutubeEmbeddedUrl = (youtubeLink: string): string => {
  let embeddedUrl = '';
  if (youtubeLink.indexOf(youtubePrefix.link) > 0) {
    embeddedUrl = youtubeLink.replace(
      youtubePrefix.link,
      youtubePrefix.embedded
    );
  } else if (youtubeLink.indexOf(youtubePrefix.embedded) > 0) {
    embeddedUrl = youtubeLink;
  }
  return embeddedUrl;
};

// Some of the youtube urls start with //, so we need to add https: to them
export const ensureHttpsForYoutubeIframes = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const iframes = Array.from(doc.getElementsByTagName('iframe'));

  const IFRAME_SRC_ATTRIBUTE = 'src';

  iframes
    .filter((iframe) => {
      const src = iframe.getAttribute(IFRAME_SRC_ATTRIBUTE);
      return (
        src &&
        (src.includes(YOUTUBE_DOMAIN) || src.includes(YOUTUBE_SHORT_DOMAIN))
      );
    })
    .forEach((iframe) => {
      const src = iframe.getAttribute(IFRAME_SRC_ATTRIBUTE);
      if (!src) return;

      const fullUrl = src.startsWith('//') ? `https:${src}` : src;
      iframe.setAttribute(IFRAME_SRC_ATTRIBUTE, fullUrl);
    });

  return doc.body.innerHTML;
};
