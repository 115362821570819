import styled from '@emotion/styled';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { Colors } from '../../../styles/colors';
import { MultiDeviceIcon } from '../../Icons/MultiDeviceIcon';
import { PaymentRequestIcon } from '../../Icons/PaymentRequestIcon';
import { UsersIcon } from '../../Icons/UsersIcon';

import { USP } from './USP';

export const USPs = () => {
  return (
    <USPContainer>
      <USP
        title="one_time_offers_usp_title_1"
        description="one_time_offers_usp_description_1"
        icon={<UsersIcon color={Colors.primary} />}
      />
      <USP
        title="one_time_offers_usp_title_2"
        description="one_time_offers_usp_description_2"
        icon={<MultiDeviceIcon color={Colors.primary} />}
      />
      <USP
        title="one_time_offers_usp_title_3"
        description="one_time_offers_usp_description_3"
        icon={<PaymentRequestIcon color={Colors.primary} />}
      />
    </USPContainer>
  );
};

const USPContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  padding: 24px 32px;

  @media ${HookMediaQueries.isDesktop} {
    flex-direction: row;
    border-top: 1px solid ${Colors.dividerColor};
  }
`;
