import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { usePrice } from '../../../hooks/usePrice';
import { PriceInformation } from '../../../models/PriceInformation';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

import { PriceRow } from './PriceRow';

type Props = {
  priceInformation: PriceInformation;
};

export const PostageRow = ({ priceInformation }: Props) => {
  const t = useTranslations();
  const { priceNumberToString } = usePrice();
  const postageCost =
    priceInformation.shippingCostPrice > 0
      ? `+ ${priceNumberToString(priceInformation.shippingCostPrice)}`
      : t('free');

  return (
    <PriceRow>
      <Postage>{t('postage')}</Postage>
      <Postage>{postageCost}</Postage>
    </PriceRow>
  );
};

const Postage = styled.h1`
  margin: 0;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;
