import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { useDeleteDeviceMutation } from '../../../services/userApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SecondaryButton } from '../../common/Button';
import { DeviceInfo } from '../../login/hooks/useDevice';

import { LastLogin } from './LastLogin';

type Props = {
  device: DeviceInfo;
  enableDeleteButton: boolean;
};

export const DeviceRow = ({ device, enableDeleteButton }: Props) => {
  const t = useTranslations();
  const [isHovered, setIsHovered] = useState(false);
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const [deleteDeviceTrigger] = useDeleteDeviceMutation();

  const showDeleteButton = enableDeleteButton && (!isDesktop || isHovered);

  const onDeleteClick = () => {
    deleteDeviceTrigger({ identifier: device.identifier });
  };

  return (
    <Container
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TextContainer>
        <Device>
          {device.platform} - {device.description}
        </Device>
        {device.lastLogin && <LastLogin lastLogin={device.lastLogin} />}
      </TextContainer>

      {showDeleteButton && (
        <DeleteButtonContainer>
          <SecondaryButton buttonSize="md" onClick={onDeleteClick}>
            {t('delete')}
          </SecondaryButton>
        </DeleteButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
`;
const TextContainer = styled.div``;
const DeleteButtonContainer = styled.div``;
const Device = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;
