import { skipToken } from '@reduxjs/toolkit/query';
import { Fragment } from 'react/jsx-runtime';

import { Messages } from '../global.d';
import { useGetProductDiscountRatesQuery } from '../services/productService';
import { useAppSelector } from '../store/hooks';

import { CardBase } from './common/CardBase';
import { RowDivider } from './common/RowDivider';
import { BuyableItem } from './dashboard/BuyableItem';
import { RecommendationCardHeader } from './RecommendationCardHeader';
import { useRecommendedBundles } from './subject/summary/hooks/useRecommendedBundles';

type Props = {
  titleKey?: keyof Messages;
};

export const RecommendationCard = ({
  titleKey = 'recommended_for_you',
}: Props) => {
  const cartItems = useAppSelector((state) => state.cart.items);
  const recommendations = useRecommendedBundles();

  const { data: productDiscountRates } = useGetProductDiscountRatesQuery(
    cartItems.length > 0 ? cartItems.length : skipToken
  );

  const amountOfProductsNeededForNextDiscount =
    (productDiscountRates?.nextDiscountRate?.amountOfProducts ?? 0) -
    cartItems.length;

  return (
    <CardBase
      fullWidth={true}
      header={
        <RecommendationCardHeader
          titleKey={titleKey}
          discountPercentage={
            productDiscountRates?.nextDiscountRate?.ratePercentage
          }
          amountOfProducts={amountOfProductsNeededForNextDiscount}
        />
      }
    >
      {recommendations?.map((buyableProduct, i) => (
        <Fragment key={buyableProduct._id}>
          <BuyableItem productBundle={buyableProduct} />
          {i < recommendations.length - 1 && <RowDivider />}
        </Fragment>
      ))}
    </CardBase>
  );
};
