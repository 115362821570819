import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Messages } from '../../../global.d';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

type Props = {
  title: keyof Messages;
  description: keyof Messages;
  icon: React.ReactNode;
};

export const USP = ({ title, description, icon }: Props) => {
  const t = useTranslations();

  return (
    <Container>
      <TitleContainer>
        {icon}
        <USPTitle>{t(title)}</USPTitle>
      </TitleContainer>
      <USPDescription>{t(description)}</USPDescription>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const USPTitle = styled.h1`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleSmall}
`;

const USPDescription = styled.h2`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.BodySmall}
`;
