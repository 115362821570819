import { format, differenceInDays } from 'date-fns';
import { nl } from 'date-fns/locale';
import { useTranslations } from 'use-intl';

import { Subject } from '../models/Subject';

export const enum DateFormat {
  /* Example: 11 okt. 2024 09:10:42*/
  fullDateWithTime = 'd MMM yyyy HH:mm:ss',
  /* Example: 11 okt. 2024*/
  fullDate = 'dd MMM yyyy',
  /* Example: 11 oktober */
  shortDate = 'dd MMMM',
}

export const getSubjectDateString = (
  startDate: Date | undefined,
  t: ReturnType<typeof useTranslations>
) => {
  if (!startDate) return t('no_exam_date_available');

  const days = differenceInDays(startDate, new Date());
  const startDateString = format(startDate, DateFormat.shortDate, {
    locale: nl,
  });
  return `${startDateString} ${t('still_x_amount_of_days_till_exam', { count: days })}`;
};

export const getHowManyDaysTillExamText = (
  startDate: Date,
  t: ReturnType<typeof useTranslations>
) => {
  const days = differenceInDays(startDate, new Date());
  if (days !== 0) {
    return t('still_x_amount_of_days_till_exam_short', { count: days });
  }
  return t('exam_is_today');
};

export const getFirstUpcomingExamDate = (
  subject: Subject
): Date | undefined => {
  const now = Date.now();

  const allFutureExamDates = Object.values(subject.timeslots ?? {})
    .map((slot) => new Date(slot?.startDate ?? 0).getTime())
    .filter((date) => date > now);

  return allFutureExamDates.length > 0
    ? new Date(Math.min(...allFutureExamDates))
    : undefined;
};

export const secondsToMMss = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const secondsToReadableText = (seconds: number) => {
  const roundedSeconds = Math.round(seconds);
  const minutes = Math.floor(roundedSeconds / 60);
  const remainingSeconds = roundedSeconds % 60;

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  let text = '';
  if (minutes > 0) {
    text += `${formattedMinutes}m `;
  }

  text += `${formattedSeconds}s`;

  return text;
};

export const HourDifferenceBetweenDates = (start: Date, end: Date) =>
  Math.floor((+end - +start) / 3600000);

export const DifferenceBetweenDateMinutes = (start: Date, end: Date) =>
  (end.getMinutes() - start.getMinutes() + 60) % 60;
