import styled from '@emotion/styled';

import { Colors } from '../../styles/colors';

export const RowDivider = () => {
  return <Divider />;
};

const Divider = styled.div`
  border-bottom: 1px solid ${Colors.dividerColor};
`;
