import styled from '@emotion/styled';
import { useRef, useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../../constants/MediaQueries';
import { Video } from '../../../../models/Summary/summary';
import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';
import { VideoPlayer } from '../../../common/VideoPlayer';
import { SummaryMode } from '../utils/summaryUtils';

import { VideoRecommendations } from './VideoRecommendations';

type Props = {
  videoList: Video[];
  subjectColor: string;
  summaryMode: SummaryMode;
  activeVideo: Video | undefined;
  hasVideoAccess: boolean;
  onClickVideoCard: (video: Video) => void;
};

export const Videos = ({
  videoList,
  subjectColor,
  summaryMode,
  activeVideo,
  hasVideoAccess,
  onClickVideoCard,
}: Props) => {
  const t = useTranslations();

  const showRecommendedList =
    summaryMode === SummaryMode.Both && videoList.length > 1;

  const videoContainerRef = useRef<HTMLDivElement | null>(null);
  const [videoHeight, setVideoHeight] = useState(0);

  useEffect(() => {
    if (videoContainerRef.current) {
      setVideoHeight(videoContainerRef.current.offsetHeight);
    }
  }, []);

  if (videoList.length === 0 && hasVideoAccess) {
    return <EmptyVideos>{t('videos_list_empty')}</EmptyVideos>;
  }

  return (
    <Container flex={summaryMode === SummaryMode.Video ? 'column' : 'row'}>
      {activeVideo && (
        <VideoContainer ref={videoContainerRef}>
          <VideoPlayer videoUrl={activeVideo.videoUrl} />
        </VideoContainer>
      )}

      {showRecommendedList && (
        <StickyVideoRecommendations
          videoList={videoList}
          activeVideo={activeVideo}
          onClickVideoCard={onClickVideoCard}
          subjectColor={subjectColor}
          summaryMode={summaryMode}
          maxHeight={videoHeight}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{ flex: string }>`
  display: flex;
  flex-direction: ${({ flex }) => flex};

  padding: 0px;
  width: 100%;

  @media ${HookMediaQueries.isDesktop} {
    padding: 0 20px;
    width: calc(100% - 40px);
    flex-direction: ${({ flex }) => flex};
  }

  gap: 20px;
  max-width: 800px;
  margin: 20px auto;

  background-color: ${Colors.summaryBackgroundColor};
`;

const VideoContainer = styled.div`
  flex: 6;
  max-width: 800px;
  width: 100%;
  margin: auto;
  border-radius: 0px;

  @media ${HookMediaQueries.isDesktop} {
    padding: 20px 0;
    border-radius: 16px;
  }
`;

const EmptyVideos = styled.p`
  text-align: center;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.BodyText}
`;

const StickyVideoRecommendations = styled(VideoRecommendations)<{
  maxHeight: number;
}>`
  flex: 4;
  padding: 20px;
  display: none;

  @media ${HookMediaQueries.isDesktop} {
    padding: 0px;
    display: block;
    max-height: ${({ maxHeight }) => `${maxHeight}px`};
    overflow-y: auto;
  }
`;
