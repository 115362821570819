import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../../constants/routes';
import { useAccountSubjects } from '../../../hooks/onboarding/useAccountSubjects';
import { useError } from '../../../hooks/useError';
import { SubjectCategory } from '../../../models/Subject';
import {
  useGetUserQuery,
  usePutEditSubjectsMutation,
} from '../../../services/userApi';
import { SelectSubjectsCard } from '../../onboarding/subjects/SelectSubjectsCard';

export const ChangeSubjects = () => {
  const { data: user } = useGetUserQuery();
  const [editSubjectsTrigger] = usePutEditSubjectsMutation();
  const navigate = useNavigate();
  const { showErrorModal } = useError();

  const role = user?.accountType;

  const {
    addOrRemoveSelectedSubject,
    selectedSubjects,
    getNextCourseLevel,
    subjects,
    courseLevel,
  } = useAccountSubjects(role);

  const onContinue = async () => {
    const nextCourseLevel = getNextCourseLevel();
    if (nextCourseLevel) {
      navigate(`${appRoutes.ProfileChangeSubjects}?level=${nextCourseLevel}`);
      return;
    }
    updateSubjects();
  };

  const updateSubjects = async () => {
    try {
      await editSubjectsTrigger({ subjects: selectedSubjects }).unwrap();
      navigate(appRoutes.Profile);
    } catch (error) {
      showErrorModal(error);
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  const openDifferentCourseLevelSubjectSelection = (
    category: SubjectCategory
  ) => {
    navigate(`${appRoutes.ProfileChangeSubjects}/${category}`);
  };

  if (!subjects || role === undefined) {
    return <></>;
  }

  return (
    <SelectSubjectsCard
      courseLevelEncoded={courseLevel}
      onContinue={onContinue}
      onBack={onBack}
      openDifferentCourseLevelSubjectSelection={
        openDifferentCourseLevelSubjectSelection
      }
      role={role}
      subjects={subjects}
      selectedSubjects={selectedSubjects}
      onSubjectSelect={addOrRemoveSelectedSubject}
    />
  );
};
