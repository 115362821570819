import styled from '@emotion/styled';
import DOMPurify from 'dompurify';

import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

import { LockIcon } from './Icons/LockIcon';

export const LockedSubjectChapter = ({ title }: { title: string }) => {
  return (
    <LockedContainer>
      <LockIcon color={Colors.disabledContentColor} />
      <LockedTitle
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(title),
        }}
      />
    </LockedContainer>
  );
};

const LockedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const LockedTitle = styled.h4`
  color: ${Colors.disabledContentColor};
  ${TextStyles.ListItemTitle}
`;
