import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Deal, DealType } from '../../../models/Deal';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { BadgeBronze } from '../../Icons/BadgeBronze';
import { BadgeGold } from '../../Icons/BadgeGold';
import { BadgeSilver } from '../../Icons/BadgeSilver';

type Props = {
  deal: Deal;
};

export const DealCardBadge = ({ deal }: Props) => {
  const t = useTranslations();

  const getBadgeColor = () => {
    if (deal.type === DealType.GOLD) {
      return Colors.goldText;
    }
    if (deal.type === DealType.SILVER) {
      return Colors.silverText;
    }
    return Colors.bronzeText;
  };

  const renderBadge = () => {
    if (deal.type === DealType.GOLD) {
      return <GoldenBadge />;
    }
    if (deal.type === DealType.SILVER) {
      return <SilverBadge />;
    }
    return <BronzeBadge />;
  };

  return (
    <>
      {renderBadge()}
      <BadgeText color={getBadgeColor()}>{t(`badge_${deal.type}`)}</BadgeText>
    </>
  );
};

const GoldenBadge = styled(BadgeGold)`
  position: absolute;
  top: -16px;
  width: 174px;
  height: 40px;
`;

const SilverBadge = styled(BadgeSilver)`
  position: absolute;
  top: -16px;
  width: 71px;
  height: 39px;
`;

const BronzeBadge = styled(BadgeBronze)`
  position: absolute;
  top: -16px;
  width: 70px;
  height: 39px;
`;

const BadgeText = styled.span<{ color: string }>`
  position: absolute;
  top: -12px;
  margin: 0;
  color: ${({ color }) => color};
  ${TextStyles.TitleExtraSmall}
`;
