import { IconProps, Icon } from './Icon';

export const Checkmark = ({ color, ...props }: IconProps) => (
  <Icon
    {...props}
    color={color}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.4769 4.02344C18.8441 4.39062 18.8441 4.98437 18.4769 5.34766L8.16439 15.6641C7.7972 16.0312 7.20345 16.0312 6.84017 15.6641L1.52377 10.3516C1.15658 9.98438 1.15658 9.39063 1.52377 9.02734C1.89095 8.66406 2.4847 8.66016 2.84798 9.02734L7.49642 13.6758L17.1488 4.02344C17.516 3.65625 18.1097 3.65625 18.473 4.02344H18.4769Z"
      fill={color}
    />
  </Icon>
);
