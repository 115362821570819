import { BuyableType } from '../../../../constants/buyableType';
import { useGetBuyAbleProductBundlesForUserQuery } from '../../../../services/userApi';
import { useAppSelector } from '../../../../store/hooks';

export const useRecommendedBundles = (
  subjectId?: string,
  buyableTypes?: BuyableType[],
  limit?: number
) => {
  const cartItems = useAppSelector((state) => state.cart.items);
  const bundleIds = cartItems.map((item) => item._id);
  const { data: buyableBundles } = useGetBuyAbleProductBundlesForUserQuery({
    subjectId: subjectId,
    productBundleIds: bundleIds.length > 0 ? bundleIds : undefined,
    productTypes: buyableTypes,
    limit: limit,
  });

  return buyableBundles;
};
