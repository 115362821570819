import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { appRoutes } from '../../../constants/routes';
import { useAccountCourseLevel } from '../../../hooks/onboarding/useAccountCourseLevel';
import { useGetUserSubjectsQuery } from '../../../services/dashboardApi';
import { useAppDispatch } from '../../../store/hooks';
import { setSubjects } from '../../../store/slices/userManagementSlice';
import { PrimaryButton } from '../../common/Button';
import { CardBase, FooterAlignment } from '../../common/CardBase';
import { SelectCourseLevelSection } from '../../onboarding/role/SelectCourseLevelSection';

export const ChangeCourseLevel = () => {
  const dispatch = useAppDispatch();
  const t = useTranslations();
  const { data: subjects, isLoading } = useGetUserSubjectsQuery();
  const allSubjects = subjects?.map((subject) => subject.subject) || [];
  const navigate = useNavigate();

  const {
    onCourseLevelSelect,
    selectedCourseLevels,
    selectedRole,
    updateCourseLevel,
  } = useAccountCourseLevel();

  const onContinue = async () => {
    const success = await updateCourseLevel();
    if (!success) return;
    navigateToChangeSubjects();
  };

  const navigateToChangeSubjects = () => {
    dispatch(setSubjects(allSubjects));
    navigate(appRoutes.ProfileChangeSubjects);
  };

  return (
    <StyledCardBase
      fullWidth={true}
      header={t('select_role_header')}
      footerButtons={
        <>
          <PrimaryButton
            buttonSize="md"
            onClick={onContinue}
            disabled={!selectedCourseLevels.length || isLoading}
          >
            {t('continue_alt')}
          </PrimaryButton>
        </>
      }
      footerAlignment={FooterAlignment.right}
      fullHeightBody={true}
    >
      <ScrollContainer>
        {selectedRole !== undefined && (
          <SelectCourseLevelSection
            onCourseLevelClick={onCourseLevelSelect}
            selectedCourseLevels={selectedCourseLevels}
            selectedRole={selectedRole}
          />
        )}
      </ScrollContainer>
    </StyledCardBase>
  );
};

const StyledCardBase = styled(CardBase)`
  margin: 0;
  height: calc(100vh - env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  overflow-y: scroll;

  @media ${HookMediaQueries.isDesktop} {
    margin-top: 24px;
    height: 80vh;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: scroll;
`;
