import { AccountType } from '../constants/AccountType';
import { Messages } from '../global.d';

export function accountTypeNumberToTranslationKey(
  accountType: number
): keyof Messages {
  switch (accountType) {
    case AccountType.student:
      return 'account_type_student';
    case AccountType.teacher:
      return 'account_type_teacher';
    case AccountType.parentCaretaker:
      return 'account_type_parent_caretaker';
    default:
      throw new Error('Invalid account type');
  }
}
