import { Capacitor } from '@capacitor/core';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Platform } from '../constants/platforms';
import { appRoutes } from '../constants/routes';
import { VersionState } from '../models/Version';
import { useLazyGetVersionQuery } from '../services/versionApi';

import { useIsWeb } from './useIsWeb';
import { ModalTypes, useModal } from './useModal';

const HAS_ALREADY_CHECKED_VERSION = 'hasAlreadyCheckedVersion';

export const useAppUpdate = () => {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const [checkVersion] = useLazyGetVersionQuery();
  const isWeb = useIsWeb();

  const checkForUpdate = async () => {
    if (isWeb) return;

    const result = await AppUpdate.getAppUpdateInfo();
    const availableVersion = result.availableVersionName;
    const currentVersion = result.currentVersionName;

    const platform = Capacitor.getPlatform() as Platform;

    const versionData = await checkVersion({
      platform,
      version: currentVersion,
    }).unwrap();

    if (versionData?.versionState === VersionState.Flexible) {
      showFlexibleUpdateModalIfNotAlreadyShown(
        availableVersion || currentVersion
      );
      return;
    }

    if (versionData?.versionState === VersionState.Immediate) {
      navigate(appRoutes.ImmediateUpdate, { replace: true });
    }
  };

  // don't show the modal again if this version has already been shown
  const showFlexibleUpdateModalIfNotAlreadyShown = (version: string) => {
    const hasAlreadyCheckedVersion = localStorage.getItem(
      HAS_ALREADY_CHECKED_VERSION
    );
    if (hasAlreadyCheckedVersion !== version) {
      showModal({
        type: ModalTypes.AppUpdateModal,
      });
      localStorage.setItem(HAS_ALREADY_CHECKED_VERSION, version);
    }
  };

  useEffect(() => {
    checkForUpdate();
  }, []);

  const startUpdateOrOpenAppStore = async (immediateUpdate: boolean) => {
    if (isWeb) return;

    const result = await AppUpdate.getAppUpdateInfo();
    if (result.flexibleUpdateAllowed && !immediateUpdate) {
      await AppUpdate.startFlexibleUpdate();
      return;
    }

    if (result.immediateUpdateAllowed && immediateUpdate) {
      await AppUpdate.performImmediateUpdate();
      return;
    }

    await AppUpdate.openAppStore();
  };

  return {
    startUpdateOrOpenAppStore,
  };
};
