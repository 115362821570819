import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  dismissModal as sliceDismissModal,
  showModal as sliceShowModal,
  clearModal as sliceClearModal,
} from '../store/slices/modalSlice';

// The modal shower tries to match the modal type to the modal component,
// based on the value of the enum and the name of the component/file.
// If the modal type does not match the component/file name, the modal will not be shown.
export const enum ModalTypes {
  StartTrainingModal = 'StartTrainingModal',
  ExitTrainingModal = 'ExitTrainingModal',
  FlagQuestionModal = 'FlagQuestionModal',
  ErrorModal = 'ErrorModal',
  PresentModal = 'PresentModal',
  ChooseSchoolModal = 'ChooseSchoolModal',
  ChooseUsernameModal = 'ChooseUsernameModal',
  BuySectionModal = 'BuySectionModal',
  EditProfileInfoModal = 'EditProfileInfoModal',
  AddClassModal = 'AddClassModal',
  RequiredGradeModal = 'RequiredGradeModal',
  AutomaticLogOutModal = 'AutomaticLogOutModal',
  BadgeUnlockedModal = 'BadgeUnlockedModal',
  BadgeDetailsModal = 'BadgeDetailsModal',
  EditProfilePictureModal = 'EditProfilePictureModal',
  LeaderBoardModal = 'LeaderBoardModal',
  SummaryTutorialModal = 'SummaryTutorialModal',
  DeleteAccountModal = 'DeleteAccountModal',
  PaymentLinkModal = 'PaymentLinkModal',
  WelcomingGiftModal = 'WelcomingGiftModal',
  ExtraSubjectModal = 'ExtraSubjectModal',
  AppUpdateModal = 'AppUpdateModal',
  TopicDetailModal = 'TopicDetailModal',
  ProductTypeDetailsModal = 'ProductTypeDetailsModal',
}

export const useModal = () => {
  const dispatch = useAppDispatch();

  const modalActive = useAppSelector((state) => state.modal.modalActive);

  const showModal = (modal: {
    type: ModalTypes;
    data?: any;
    style?: string;
    showClose?: boolean;
  }) => {
    dispatch(sliceShowModal(modal));
  };

  const dismissModal = () => {
    dispatch(sliceDismissModal());
  };

  const clearModal = () => {
    dispatch(sliceClearModal());
  };

  return { showModal, dismissModal, clearModal, modalActive };
};
