import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Illustrations } from '../../constants/Illustrations';
import { HookMediaQueries } from '../../constants/MediaQueries';
import { AppRoutePathNames, appRoutes } from '../../constants/routes';
import { SecondaryButton } from '../common/Button';
import { BaseNavHeader } from '../navbar/BaseNavHeader';

export const OnboardingHeader = () => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const navigate = useNavigate();
  const t = useTranslations();

  const isOneTimeOffer = useLocation().pathname.includes(
    AppRoutePathNames.OneTimeOffer
  );

  if (!isDesktop) {
    return <></>;
  }

  const handleSkip = () => {
    navigate(appRoutes.Dashboard);
  };

  return (
    <BaseNavHeader>
      <LeftContainer>
        <Logo src={Illustrations.exoLogo} onClick={() => navigate('/login')} />
      </LeftContainer>
      {isOneTimeOffer && (
        <RightContainer>
          <SecondaryButton buttonSize="md" onClick={handleSkip}>
            {t('skip_deals')}
          </SecondaryButton>
        </RightContainer>
      )}
    </BaseNavHeader>
  );
};
const Logo = styled.img`
  margin-right: 8px;
  height: 38px;
  cursor: pointer;
`;

const LeftContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
