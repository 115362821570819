import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { ActionSubjectCard } from '../../../../components/ActionSubjectCard';
import { VideoActionSubjectCard } from '../../../../components/VideoActionSectionCard';
import { BuyableType } from '../../../../constants/buyableType';
import { HookMediaQueries } from '../../../../constants/MediaQueries';
import { AppRoutePathNames } from '../../../../constants/routes';
import { ModalTypes, useModal } from '../../../../hooks/useModal';
import { useProductAccess } from '../../../../hooks/useProductAccess';
import { Section, Sections } from '../../../../models/Summary/summary';
import { useGetNextUnreadSectionsQuery } from '../../../../services/subjectsApi';
import { Colors } from '../../../../styles/colors';
import { CrownIcon } from '../../../Icons/Crown';
import { HistoryIcon } from '../../../Icons/HistoryIcon';
import { useSectionActions } from '../useSectionActions';
import { useSubjectHome } from '../useSubjectHome';

type Props = {
  subjectColor?: string;
  sections?: Sections;
  encodedSubjectName: string;
  progressPercentageTraining: number;
};

export const LearnNextCard = ({
  subjectColor,
  sections,
  encodedSubjectName,
  progressPercentageTraining,
}: Props) => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { openSummary, openVideo, openTraining } = useSectionActions();
  const {
    getFirstPracticeSectionAvailable,
    firstUntrainedPassedSection,
    nextVideo,
    nextSectionWithVideo,
    firstSection,
    areExamTrainingVideosEnabled,
    userHasAccessToExamTrainingVideo,
    getSectionByEncodedSectionName,
  } = useSubjectHome({
    encodedSubjectName,
  });
  const { hasExplanationVideoAccess } = useProductAccess();
  const { data: nextUnreadSections } = useGetNextUnreadSectionsQuery();
  const nextUnreadSection = nextUnreadSections?.find(
    (section) => section.encodedSubjectName === encodedSubjectName
  );

  const { showModal } = useModal();

  const learningText = `${nextUnreadSection ? t('continue') : t('start')} ${t(
    'learning_text'
  )}`;

  const trainingText = `${
    progressPercentageTraining > 0 ? t('continue') : t('start')
  } ${t('training_text')}`;
  const repeatTrainingText = t('again_training');
  const trainingTitleText =
    progressPercentageTraining !== 1 ? trainingText : repeatTrainingText;
  const hasPracticeModule =
    !!sections?.practiceModuleActive && !!getFirstPracticeSectionAvailable();
  const showVideosAsSmallCards =
    (!!nextVideo && !!areExamTrainingVideosEnabled) || !hasPracticeModule;

  const getEncodedChapterNameFromSection = (section: Section) => {
    if (!sections) return '';
    const chapter = sections.chapters.find((chapter) =>
      chapter.sections.some(
        (chapterSection) => section._id === chapterSection._id
      )
    );
    return chapter?.encodedChapterName || '';
  };

  const onTrainingClick = () => {
    const section =
      firstUntrainedPassedSection || getFirstPracticeSectionAvailable();
    if (!section) return;
    openTraining({
      section,
      encodedChapterName: getEncodedChapterNameFromSection(section),
    });
  };

  const onVideoActionSubjectCardPress = () => {
    if (!sections || !nextSectionWithVideo) return;

    if (hasExplanationVideoAccess(nextSectionWithVideo, sections)) {
      openVideo(nextSectionWithVideo);
      return;
    }

    showBuyableModal(BuyableType.Videos);
  };

  const onExamTrainingClick = () => {
    if (userHasAccessToExamTrainingVideo) {
      navigate(`../${AppRoutePathNames.ExamTrainingVideo}`);
      return;
    }
    showBuyableModal(BuyableType.TrainingVideos);
  };

  const showBuyableModal = (buyableType: BuyableType) => {
    showModal({
      type: ModalTypes.BuySectionModal,
      data: {
        encodedSubjectName,
        buyableType,
      },
    });
  };

  const openSummarySection = () => {
    const section = nextUnreadSection
      ? getSectionByEncodedSectionName(nextUnreadSection.encodedSectionName)
      : firstSection;

    if (!section) return;

    openSummary(section);
  };

  if (!sections) return <></>;

  return (
    <Container>
      <StyledColumn>
        <StyledActionSubjectCard
          subjectColor={subjectColor}
          onPress={openSummarySection}
          title={learningText}
          subTitle={
            nextUnreadSection
              ? nextUnreadSection?.sectionTitle
              : firstSection?.sectionTitle
          }
        />

        {nextVideo && (
          <StyledVideoActionSubjectCard
            onPress={onVideoActionSubjectCardPress}
            subjectColor={subjectColor}
            tagTitle={t('common_explanation')}
            backgroundImage={nextVideo?.thumbnailUrl}
            iconVariation={<CrownIcon />}
            highlightedText={t('start')}
            regularText={t('subject_card_video')}
            showVideosAsSmallCards={showVideosAsSmallCards}
          />
        )}

        {hasPracticeModule && (
          <StyledActionSubjectCard
            subjectColor={subjectColor}
            onPress={onTrainingClick}
            title={trainingTitleText}
            subTitle={firstUntrainedPassedSection?.sectionTitle}
          />
        )}

        {areExamTrainingVideosEnabled && (
          <StyledVideoActionSubjectCard
            onPress={onExamTrainingClick}
            subjectColor={subjectColor}
            tagColor={Colors.examTrainingColor}
            tagTitle={t('repeat_exam_training')}
            backgroundImage={nextVideo?.thumbnailUrl}
            iconVariation={<HistoryIcon />}
            highlightedText={t('rewatch_livestream_start')}
            regularText={t('rewatch_livestream')}
            showVideosAsSmallCards={showVideosAsSmallCards}
          />
        )}
      </StyledColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 20px 20px 0px 20px;

  @media ${HookMediaQueries.isDesktop} {
    flex-direction: row;
    padding: 20px 0px 0px 0px;
    width: 100%;
  }
`;
const StyledColumn = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media ${HookMediaQueries.isDesktop} {
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledVideoActionSubjectCard = styled(VideoActionSubjectCard)<{
  showVideosAsSmallCards: boolean;
}>`
  grid-row: span 4;
  height: 176px;
  min-height: 76px;
  order: 3;

  @media ${HookMediaQueries.isDesktop} {
    order: 0;
    height: inherit;
    ${({ showVideosAsSmallCards }) =>
      showVideosAsSmallCards &&
      `
        grid-row: span 2;
      `}
  }
`;

const StyledActionSubjectCard = styled(ActionSubjectCard)`
  grid-row: span 2;
`;
