import { useNavigate } from 'react-router-dom';

import { SUBJECT_SELECTION } from '../../../constants/onboardingSteps';
import { appRoutes } from '../../../constants/routes';
import { useAccountSubjects } from '../../../hooks/onboarding/useAccountSubjects';
import { useOnboardingProgress } from '../../../hooks/onboarding/useOnboardingProgress';
import { useRegister } from '../../../hooks/onboarding/useRegister';
import { useUserManagement } from '../../../hooks/useUserManagement';
import { SubjectCategory } from '../../../models/Subject';
import { UserRole } from '../../../models/UserRole';

import { SelectSubjectsCard } from './SelectSubjectsCard';

const ONBOARDING_STEP = SUBJECT_SELECTION;

export const SelectSubjects = () => {
  const navigate = useNavigate();
  const { register } = useRegister();

  const userManagementState = useUserManagement();
  const role = userManagementState.accountType;

  const {
    addOrRemoveSelectedSubject,
    selectedSubjects,
    getNextCourseLevel,
    subjects,
    courseLevel,
  } = useAccountSubjects(role);

  const extraStep =
    userManagementState.teacherCourseLevels?.indexOf(courseLevel) || 0;

  const currentStep = ONBOARDING_STEP + extraStep;
  const { progress, previousProgress } = useOnboardingProgress(currentStep);

  const onContinue = async () => {
    const nextCourseLevel = getNextCourseLevel();
    if (nextCourseLevel) {
      navigate(`${appRoutes.OnboardingSubjects}?level=${nextCourseLevel}`);
      return;
    }

    if (role !== UserRole.student) {
      register();
      return;
    }
    navigate(appRoutes.OnboardingRelevantSection);
  };

  const onBack = () => {
    navigate(-1);
  };

  const openDifferentCourseLevelSubjectSelection = (
    category: SubjectCategory
  ) => {
    navigate(`${appRoutes.OnboardingSubjects}/${category}`);
  };

  if (!subjects || role === undefined) {
    return <></>;
  }

  return (
    <SelectSubjectsCard
      courseLevelEncoded={courseLevel}
      onContinue={onContinue}
      onBack={onBack}
      openDifferentCourseLevelSubjectSelection={
        openDifferentCourseLevelSubjectSelection
      }
      role={role}
      subjects={subjects}
      selectedSubjects={selectedSubjects}
      onSubjectSelect={addOrRemoveSelectedSubject}
      progress={progress}
      previousProgress={previousProgress}
    />
  );
};
