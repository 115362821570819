import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaymentStatus } from '../../models/payments';
import { ProductBundle } from '../../models/ProductBundle';
import { Slices } from '../Slices';

type CartState = {
  items: ProductBundle[];
  discountCode?: string;
  paymentStatus?: string;
  paymentId?: string;
};

const initialState: CartState = {
  items: [],
  discountCode: '',
  paymentStatus: '',
  paymentId: '',
};

const cartSlice = createSlice({
  name: Slices.Cart,
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<ProductBundle>) => {
      const product = action.payload;
      const cartProductFound = state.items.find(
        (item) => item._id === product._id
      );
      if (!cartProductFound) {
        state.items.push(product);
      }
    },
    addMultipleToCart: (state, action: PayloadAction<ProductBundle[]>) => {
      const productBundles = action.payload;
      const bundlesToAdd = productBundles.filter(
        (bundle) => !state.items.some((item) => item._id === bundle._id)
      );
      state.items.push(...bundlesToAdd);
    },
    addDiscount: (state, action: PayloadAction<string>) => {
      state.discountCode = action.payload;
    },
    removeDiscount: (state) => {
      state.discountCode = undefined;
    },
    deleteFromCart: (state, action: PayloadAction<ProductBundle>) => {
      const productId = action.payload._id;
      state.items = state.items.filter((item) => item._id !== productId);
    },
    setPaymentStatus: (state, action: PayloadAction<PaymentStatus>) => {
      state.paymentStatus = action.payload;
    },
    setPaymentId: (state, action: PayloadAction<string>) => {
      state.paymentId = action.payload;
    },
    clearCart: (state) => {
      state.items = [];
      state.paymentStatus = undefined;
      state.paymentId = undefined;
      state.discountCode = undefined;
    },
  },
});

export const {
  addToCart,
  addMultipleToCart,
  addDiscount,
  removeDiscount,
  deleteFromCart,
  setPaymentStatus,
  setPaymentId,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
