import styled from '@emotion/styled';

import { SecondaryButton, PrimaryButton } from '../components/common/Button';
import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

import { Price } from './common/Price';
import { CartAdd } from './Icons/CartAdd';
import { CloseIcon } from './Icons/CloseIcon';

export enum CardType {
  addToCart = 'addToCart',
  removeFromCart = 'removeFromCart',
}

type Props = {
  image: string;
  title: string;
  subtitle: string;
  price?: number;
  labelText?: string;
  onPress?: () => void;
  oldPrice?: number;
  discountPercentage?: number;
  cardType?: CardType;
};

export const CartItem = ({
  image,
  title,
  subtitle,
  price,
  onPress,
  oldPrice,
  discountPercentage,
  cardType = CardType.addToCart,
  ...props
}: Props) => {
  const renderButton = () => {
    switch (cardType) {
      case CardType.addToCart:
        return (
          <StyledPrimaryButton buttonSize="sm" onClick={onPress}>
            <CartAdd size="lg" color={Colors.primaryButtonContentColor} />
          </StyledPrimaryButton>
        );

      case CardType.removeFromCart:
        return (
          <StyledSecondaryButton
            buttonSize="sm"
            textSize="lg"
            onClick={onPress}
          >
            <CloseIcon />
          </StyledSecondaryButton>
        );

      default:
        break;
    }
  };
  return (
    <Container {...props}>
      <Image src={image} alt="Product Image" />
      <UpSellDetails>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Price price={price || 0} oldPrice={oldPrice} />
      </UpSellDetails>
      {renderButton()}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 20px;
`;

const Title = styled.div`
  ${TextStyles.ListItemTitle}
`;
const Subtitle = styled.div`
  word-break: break-all;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;

const UpSellDetails = styled.div`
  gap: 4px;
  flex: 1;
  justify-content: center;
  align-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: 68px;
  max-height: 80px;
  object-fit: contain;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  border-radius: 50px;
  display: flex;
  padding: 6px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
`;
