export const MATHJAX_DEFAULT_CONFIG = {
  loader: { load: ['[tex]/html', '[tex]/textcomp'] },
  tex: {
    packages: { '[+]': ['html', 'textcomp'] },
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]'],
    ],
  },
};
