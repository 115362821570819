import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  SUBJECT_ID_JOIN_PREFIX,
  SUBJECT_ID_PREFIX,
} from '../../../constants/constants';
import { Illustrations } from '../../../constants/Illustrations';
import { HookMediaQueries } from '../../../constants/MediaQueries';
import { RELEVANT_SECTION_SELECTION } from '../../../constants/onboardingSteps';
import { appRoutes } from '../../../constants/routes';
import { useOnboardingProgress } from '../../../hooks/onboarding/useOnboardingProgress';
import { useUserManagement } from '../../../hooks/useUserManagement';
import { Subject } from '../../../models/Subject';
import { Section } from '../../../models/Summary/summary';
import { useGetSubjectsSectionsQuery } from '../../../services/onboardingApi';
import { useAppDispatch } from '../../../store/hooks';
import {
  setDifficultSections,
  setDifficultSubjects,
} from '../../../store/slices/userManagementSlice';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { PrimaryButton } from '../../common/Button';
import { CardBase, FooterAlignment } from '../../common/CardBase';
import { ProgressBar } from '../../ProgressBar';
import { CardHeader } from '../CardHeader';

import { SubjectRow } from './SubjectRow';

const ONBOARDING_STEP = RELEVANT_SECTION_SELECTION;

export const RelevantSectionCard = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userManagementState = useUserManagement();
  const subjects = userManagementState.subjects || [];
  const mainCourseLevel = userManagementState.courseLevel;
  const subjectIds = subjects.map((subject) => subject._id);
  const subjectIdsString =
    SUBJECT_ID_PREFIX + subjectIds.join(SUBJECT_ID_JOIN_PREFIX);

  const { progress, previousProgress } = useOnboardingProgress(ONBOARDING_STEP);

  const { data: sections } = useGetSubjectsSectionsQuery({
    subjectIds: subjectIdsString,
  });

  const handleContinue = () => {
    navigate(appRoutes.OnboardingStudentType);
  };

  const handleSectionSelect = (section: Section, subject: Subject) => {
    dispatch(setDifficultSections([section.encodedSectionName]));
    dispatch(setDifficultSubjects([subject._id]));
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <StyledCardBase
      fullWidth={true}
      fullWidthBody={true}
      header={<CardHeader title={t('most_relevant_topic')} onBack={onBack} />}
      footerButtons={
        <PrimaryButton
          buttonSize="md"
          onClick={handleContinue}
          disabled={!userManagementState.difficultSections?.length}
        >
          {t('continue_alt')}
        </PrimaryButton>
      }
      footerAlignment={FooterAlignment.right}
      fullHeightBody={true}
    >
      <ProgressBarContainer>
        <ProgressBar
          progress={progress}
          roundedCorners={false}
          initialWidth={previousProgress}
        />
      </ProgressBarContainer>
      <FlexContainer>
        <ExplanationContainer>
          <Logo src={Illustrations.exoLogoHat} />
          <TextContainer>
            <Title>{t('how_does_it_work')}</Title>
            <Body>{t('how_does_it_work_explanation_1')}</Body>
            <Body>{t('how_does_it_work_explanation_2')}</Body>
            <Body>{t('how_does_it_work_explanation_3')}</Body>
          </TextContainer>
        </ExplanationContainer>
        <ScrollContainer>
          <form>
            {sections?.map((subject) => (
              <SubjectRow
                key={subject._id}
                subject={subject}
                showCourseLevel={mainCourseLevel !== subject.courseLevel}
                onSelect={handleSectionSelect}
                selectedSectionName={userManagementState.difficultSections?.[0]}
              />
            ))}
          </form>
        </ScrollContainer>
      </FlexContainer>
    </StyledCardBase>
  );
};
const Logo = styled.img`
  width: 100px;
  cursor: pointer;
`;
const StyledCardBase = styled(CardBase)`
  margin: 0;
  height: 100vh;
  position: relative;
  @media ${HookMediaQueries.isDesktop} {
    margin-top: 24px;
    height: 80vh;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;

  @media ${HookMediaQueries.isDesktop} {
    flex-direction: row;
  }
`;
const ExplanationContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 12px;
  border-right: 1px solid ${Colors.inputBorderColor};
  padding: 20px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Title = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleBig}
`;
const Body = styled.div`
  white-space: pre-line;
  color: ${Colors.primaryTextColor};
  ${TextStyles.BodyMedium}
`;

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const ProgressBarContainer = styled.div`
  position: absolute;
  width: 100%;
`;
