import { ApiTagTypes } from '../constants/api';
import { Payment } from '../models/payments';

import { baseApi } from './baseApi';

export const paymentService = baseApi
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.ProductBundles],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getPaymentStatus: build.query<Payment, { paymentId: string }>({
        query: ({ paymentId }) => ({
          url: `/payments/${paymentId}`,
        }),
      }),
    }),
  });

export const { useGetPaymentStatusQuery, useLazyGetPaymentStatusQuery } =
  paymentService;
