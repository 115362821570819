import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { appRoutes } from '../../../constants/routes';
import { ProductBundle } from '../../../models/ProductBundle';
import { useAppDispatch } from '../../../store/hooks';
import { addToCart } from '../../../store/slices/cartSlice';
import { TextStyles } from '../../../styles/textStyles';
import { SecondaryButton } from '../../common/Button';

type Props = {
  buyableProduct?: ProductBundle;
};

export const ExerciseCardHeader = ({ buyableProduct }: Props) => {
  const t = useTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (buyableProduct) {
      dispatch(addToCart(buyableProduct));
      navigate(appRoutes.Cart);
    }
  };

  return (
    <ButtonWrapper>
      <StyledSecondaryButton buttonSize="md" onClick={handleClick}>
        {t('buy')}
      </StyledSecondaryButton>
    </ButtonWrapper>
  );
};

const StyledSecondaryButton = styled(SecondaryButton)`
  ${TextStyles.ButtonTextExtraSmallText}
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;
