import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  makeSubjectDefinitionsRoute,
  makeSubjectSummaryRoute,
} from '../../constants/routes';
import { useUpcomingExam } from '../../hooks/useUpcomingExam';
import { useGetUserSubjectsQuery } from '../../services/dashboardApi';
import { PrimaryHeaderText } from '../../styles/elements/PrimaryHeaderText';
import { getHowManyDaysTillExamText } from '../../utils/dateUtils';
import { PrimaryButton, SecondaryButton } from '../common/Button';
import { CardBase } from '../common/CardBase';
import { SubjectRow } from '../common/SubjectRow';

export const NextExamCard = () => {
  const t = useTranslations();
  const { data: subjects } = useGetUserSubjectsQuery();
  const upcomingExam = useUpcomingExam(subjects ?? []);
  const navigate = useNavigate();

  if (!upcomingExam) return <></>;

  const renderFooterButtons = () => {
    return (
      <>
        <PrimaryButton
          buttonSize="md"
          onClick={() =>
            navigate(
              makeSubjectSummaryRoute(
                upcomingExam.userSubject.subject.encodedName
              )
            )
          }
        >
          {t('common_summary')}
        </PrimaryButton>
        <SecondaryButton
          buttonSize="md"
          onClick={() =>
            navigate(
              makeSubjectDefinitionsRoute(
                upcomingExam.userSubject.subject.encodedName
              )
            )
          }
        >
          {t('subject_menu_definitions')}
        </SecondaryButton>
      </>
    );
  };

  return (
    <CardBase
      fullWidth={true}
      header={<PrimaryHeaderText>{t('next_exam')}</PrimaryHeaderText>}
      footerButtons={renderFooterButtons()}
    >
      <SubjectRow
        userSubject={upcomingExam.userSubject}
        key={upcomingExam.userSubject._id}
        subText={getHowManyDaysTillExamText(upcomingExam.date, t)}
        smallImage={false}
      />
    </CardBase>
  );
};
