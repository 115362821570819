import styled from '@emotion/styled';

import { HEADER_HEIGHT_PX } from '../../../constants/Sizes';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { CloseIcon } from '../../Icons/CloseIcon';

type Props = {
  title: string;
  description: string;
  onClose: () => void;
};

export const ExamTrainingVideoHeader = ({
  title,
  description,
  onClose,
}: Props) => {
  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
      <CloseIconContainer onClick={onClose}>
        <CloseIcon />
      </CloseIconContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: ${HEADER_HEIGHT_PX}px;
  background-color: ${Colors.modalBackgroundColor};

  border-bottom: 1px solid ${Colors.dividerColor};
`;

const Title = styled.h1`
  flex: 1;
  margin: 0;
  ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Description = styled.p`
  margin: 0;
  ${Colors.secondaryTextColor};
  ${TextStyles.BodyMedium}
`;

const CloseIconContainer = styled.div`
  padding: 20px;
  cursor: pointer;
`;
