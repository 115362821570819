import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import { useSubjectColorContext } from '../../../context/SubjectColorContext';
import { ModalTypes, useModal } from '../../../hooks/useModal';
import { ResultTopics } from '../../../models/Results';
import { useLazyGetSummaryTopicDetailsQuery } from '../../../services/trainingApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

type Props = {
  topic: ResultTopics;
};

export const ResultTopicRow = ({ topic }: Props) => {
  const { subjectColor } = useSubjectColorContext();
  const { encodedSubjectName } = useParams();
  const [getSummaryTopicDetailsTrigger] = useLazyGetSummaryTopicDetailsQuery();
  const { showModal } = useModal();

  const onTopicClick = async () => {
    if (!encodedSubjectName) return;

    try {
      const topicDetail = await getSummaryTopicDetailsTrigger({
        encodedSubjectName: encodedSubjectName,
        encodedTopicName: topic.encodedTopicName,
      }).unwrap();

      showModal({
        type: ModalTypes.TopicDetailModal,
        data: {
          topic: topicDetail,
          subjectColor,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TopicContainer onClick={onTopicClick}>
      <TopicTitle>{topic.topicTitle}</TopicTitle>
      <RatingContainer>
        <RatingText>{topic.rating}</RatingText>
        <ImprovementRateText color={subjectColor}>
          {topic.improvementRate}
        </ImprovementRateText>
      </RatingContainer>
    </TopicContainer>
  );
};

const RatingContainer = styled.div``;

const TopicContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 12px 0;
  justify-content: space-between;
  cursor: pointer;
`;
const TopicTitle = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleSmall}
`;

const RatingText = styled.div`
  ${TextStyles.ProgressTitle}
  text-align: end;
`;

const ImprovementRateText = styled.div<{ color: string }>`
  ${TextStyles.ProgressSubtitle}
  color: ${({ color }) => color};
  text-align: end;
`;
