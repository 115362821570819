const ProgressTitle = `
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
`;

const MobileHeaderTitle = `
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
`;

const PriceBigText = `
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.36px;
`;

const PriceMediumText = `
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.36px;
`;

const TextSubjectRequiredScore = `
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
`;

const CardTitleText = `
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.32px;
`;

const TextSubjectTitle = `    
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
`;

const TextSubjectSubtitle = `    
  font-family: Open Sans, "sans-serif";
  font-size: 16px;
  font-weight: 400;
  text-wrap: nowrap;
`;

const ListItemTitle = `
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
`;

const ButtonTextLargeText = `
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.3px;
`;

const BodyText = `
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.3px;
`;

const BodyMedium = `
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const BodySmall = `
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const BodyExtraSmall = `
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: justified;
`;

const ButtonTextSmallText = `
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.28px;
`;

const CardSubtitleText = `
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.28px;
`;

const ListItemBody = `
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.28px;
`;

const InputText = `
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.28px;
`;

const InputHintText = `
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.28px;
`;

const ListItemSubtitle = `
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.28px;
`;

const ProgressSubtitle = `
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: -0.26px;
`;

const DiscountLabelText = `
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const ButtonTextExtraSmallText = `
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.24px;
`;

const PriceSmallText = `
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.24px;
`;

const ProgressBody = `
  font-family: Montserrat, sans-serif;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.2px;
`;

const InputTextError = `
  font-family: Open Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
`;

const OnboardingTitleExtraLarge = `
font-family: Montserrat;
font-size: 36px;
font-weight: 600;
line-height: 44px;
`;

const ProgressAmountIndicator = `
font-family: Montserrat;
font-size: 13px;
font-weight: 400;
line-height: 16px;
letter-spacing: -0.02em;
text-align: left;
`;

const TabbarText = `
font-family: Montserrat;
font-size: 10px;
font-weight: 500;
line-height: 12px;
text-align: center;
`;

const SubjectHeader = `
font-family: Montserrat;
font-size: 24px;
font-weight: 700;
line-height: 24px;
text-align: left;
`;
const SubjectSubheader = `
font-family: Montserrat;
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
`;

const NotificationDotText = `
font-family: Montserrat;
font-size: 11px;
font-weight: 600;
line-height: 16px;
text-align: center;
`;

const SummaryReadingTitle = `
font-family: Montserrat;
font-size: 16px;
font-weight: 700;
line-height: 24px;
letter-spacing: -0.02em;
text-align: left;
`;

const SummaryReadingText = `
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: left;
`;

const VideoCardTitle = `
font-family: Montserrat;
font-size: 14px;
font-weight: 600;
line-height: 18px;
letter-spacing: -0.02em;
text-align: left;

`;

const VideoCardTime = `
font-family: Montserrat;
font-size: 12px;
font-weight: 600;
line-height: 14px;
text-align: left;
`;

const TitleBig = `
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
`;

const TitleMedium = `
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
`;

const TitleSmall = `
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
`;

const TitleExtraSmall = `
font-family: Montserrat;
font-weight: 700;
font-size: 14px;
line-height: 24px;
letter-spacing: -2%;
`;

export const OldExamsBase = `
font-family: Montserrat;
font-size: 16px;
font-weight: 700;
line-height: 24px;
letter-spacing: -0.02em;
`;

const FAQBaseText = `
font-family: Montserrat;
`;

const LeaderBoardText = `
font-family: Montserrat;
font-size: 15px;
font-weight: 400;
line-height: 20px;
letter-spacing: -0.02em;
text-align: left;
`;

const LeaderBoardScore = `
font-family: Montserrat;
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: -0.02em;
text-align: left;
 color: #adadad;
`;

const LeaderBoardDays = `
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 8px 0;
`;

const LeaderBoardSubtitle = `
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
`;

const LeaderBoardTitleBig = `
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 8px 0;
`;

const LeaderBoardPodium = `
  font-family: Montserrat;
  font-size: 39px;
  font-weight: 700;
  line-height: 47.54px;
  text-align: left;
  `;
const ScheduleExamTime = `
font-family: Montserrat;
font-size: 15px;
font-weight: 600;
line-height: 20px;
letter-spacing: -0.02em;
text-align: left;
`;

const ScheduleBase = `
  font-family: Montserrat;
`;

const ScheduleEventTitle = `
font-family: Montserrat;
font-size: 16px;
font-weight: 700;
line-height: 24px;
letter-spacing: -0.02em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

`;

const ScheduleEventTime = `
font-family: Montserrat;
font-size: 15px;
font-weight: 400;
line-height: 24px;
letter-spacing: -0.02em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

`;

const ScheduleOptionsText = `
font-family: Montserrat;
font-size: 12px;
font-weight: 600;
line-height: 16px;
letter-spacing: -0.02em;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
`;

const PaymentLinkBaseText = `
font-family: Montserrat;
`;
const DiscountPercentageText = `
font-family: Montserrat;
font-size: 12px;
font-weight: 600;
line-height: 16px;
letter-spacing: -0.02em;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
`;

const ScheduleInfoText = `
font-family: Montserrat;
font-size: 16px;
font-weight: 700;
line-height: 24px;
letter-spacing: -0.02em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
text-align: left;
`;

const PriceSmallTextDiscount = `
font-family: Montserrat;
font-size: 12px;
font-weight: 400;
line-height: 24px;
letter-spacing: -0.02em;
text-align: left;
text-decoration-line: line-through;
`;

const ExplanationVideoText = `
font-family: Montserrat;
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
`;

const OneTimeOfferDescription = `
font-family: Montserrat;
font-weight: 500;
font-size: 16px;
line-height: 32px;
letter-spacing: -2%;
`;

export const TextStyles = {
  ProgressTitle,
  PriceBigText,
  PriceMediumText,
  CardTitleText,
  ListItemTitle,
  ButtonTextLargeText,
  BodyText,
  BodyMedium,
  BodySmall,
  BodyExtraSmall,
  ButtonTextSmallText,
  CardSubtitleText,
  ListItemBody,
  InputText,
  InputHintText,
  ListItemSubtitle,
  ProgressSubtitle,
  DiscountLabelText,
  ButtonTextExtraSmallText,
  PriceSmallText,
  PriceSmallTextDiscount,
  ProgressBody,
  MobileHeaderTitle,
  InputTextError,
  TextSubjectTitle,
  TextSubjectRequiredScore,
  TextSubjectSubtitle,
  OnboardingTitleExtraLarge,
  ProgressAmountIndicator,
  TabbarText,
  SubjectHeader,
  SubjectSubheader,
  NotificationDotText,
  SummaryReadingTitle,
  SummaryReadingText,
  VideoCardTime,
  VideoCardTitle,
  TitleMedium,
  TitleBig,
  TitleSmall,
  TitleExtraSmall,
  OldExamsBase,
  FAQBaseText,
  LeaderBoardText,
  LeaderBoardScore,
  LeaderBoardDays,
  LeaderBoardSubtitle,
  LeaderBoardTitleBig,
  LeaderBoardPodium,
  ScheduleExamTime,
  ScheduleBase,
  ScheduleEventTitle,
  ScheduleEventTime,
  ScheduleOptionsText,
  ScheduleInfoText,
  PaymentLinkBaseText,
  DiscountPercentageText,
  ExplanationVideoText,
  OneTimeOfferDescription,
};
