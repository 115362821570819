import styled from '@emotion/styled';
import { ReactNode } from 'react';

import { ZIndex } from '../../constants/Zindex';
import { useModal } from '../../hooks/useModal';
import { useAppSelector } from '../../store/hooks';

type Props = {
  children: ReactNode;
};

export const Modal = ({ children, ...props }: Props) => {
  const { dismissModal } = useModal();
  const { modalActive } = useAppSelector((state) => state.modal);

  const dismissClicked = (ev?: any) => {
    if (ev.target === ev.currentTarget) {
      dismissModal();
    }
  };

  return (
    <Container visible={modalActive}>
      <ModalWrap onClick={dismissClicked}>{children}</ModalWrap>

      <Dismisser onClick={dismissClicked} />
    </Container>
  );
};

const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 20px;
  z-index: ${ZIndex.z50};
  box-sizing: border-box;

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate(0, 20px) scale(1);
    }
    1% {
      opacity: 0;
      transform: translate(0, 20px) scale(1);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0) scale(1);
    }
  }
  @keyframes fadeOutDown {
    0% {
      opacity: 1;
      transform: translate(0, 0) scale(1);
    }
    1% {
      opacity: 1;
      transform: translate(0, 0) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(0, 20px) scale(1);
    }
  }

  animation: ${(props) =>
    props.visible ? 'fadeInUp 0.3s 1' : 'fadeOutDown 0.25s forwards'};
`;

const ModalWrap = styled.div`
  position: relative;
  max-height: 100%;
  width: 100%;
  max-width: 520px;
  overflow-y: auto;
  text-align: center;
  transform: translate(0);
  z-index: ${ZIndex.z50};
  overflow: visible;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;
const Dismisser = styled.div`
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: ${ZIndex.z10};
  position: fixed;
  background-color: transparent;
`;
