import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMediaQuery } from '@uidotdev/usehooks';
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  HookMediaQueries,
  MediaQueries,
} from '../../../constants/MediaQueries';
import { HEADER_HEIGHT_PX } from '../../../constants/Sizes';
import { SubCategory } from '../../../models/Definition';
import { useGetSubjectDefinitionsByEncodedNameQuery } from '../../../services/subjectsApi';
import { SecondaryButton } from '../../common/Button';
import { CardBase } from '../../common/CardBase';
import { ArrowUpIcon } from '../../Icons/ArrowUpIcon';
import { NoContentCard } from '../NoContentCard';

import { DefinitionCardBody } from './DefinitionCardBody';
import { DefinitionCardHeader } from './DefinitionCardHeader';
import { DefinitionChapterIndex } from './DefinitionChapterIndex';
import { fuseSearch } from './util/fuseSearch';

export const SubjectDefinitions = () => {
  const t = useTranslations();
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);

  const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategory>();
  const [visibleSubCategoryId, setVisibleSubCategoryId] = useState('');
  const { encodedSubjectName } = useParams();

  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const onScroll = () => setShowScrollToTop(window.scrollY > 0);
    window.addEventListener('scroll', onScroll);

    return () => {
      // return the cleanup function in the useEffect:
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const { data } = useGetSubjectDefinitionsByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );
  const [searchQuery, setSearchQuery] = useState('');

  const searchResults = fuseSearch(searchQuery, data);

  const handleClick = (subCategory: SubCategory) => {
    setSelectedSubCategory(subCategory);
  };

  const onChangeVisibleCategory = (categoryId: string) => {
    setVisibleSubCategoryId(categoryId);
  };

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const userInput = event.target.value;
    setSearchQuery(userInput);
  };

  const getHeaderTitle = () =>
    searchResults.length > 0 ? searchResults[0].name : '';

  const getHeaderSubTitle = () =>
    searchResults.length > 0 && searchResults[0].subcategories.length > 0
      ? searchResults[0].subcategories[0].name
      : '';

  if (!data) {
    return <NoContentCard />;
  }

  return (
    <DefinitionsContainer>
      {isDesktop && (
        <CardContainerLeft>
          <CardBase fullWidth={true} header={t('subject_index_header')}>
            {searchResults.map((category) => (
              <DefinitionChapterIndex
                visibleSubCategoryId={visibleSubCategoryId}
                category={category}
                key={category._id}
                handleClick={handleClick}
              />
            ))}
          </CardBase>
        </CardContainerLeft>
      )}
      <CardContainerRight>
        <CardBase
          fullWidth={true}
          fullWidthBody={true}
          header={
            <DefinitionCardHeader
              title={getHeaderTitle()}
              subTitle={getHeaderSubTitle()}
              searchBar={true}
              onChange={handleSearchChange}
            />
          }
        >
          <DefinitionCardBody
            categories={searchResults}
            selectedSubCategory={selectedSubCategory}
            onChangeVisibleCategory={onChangeVisibleCategory}
          />
          {!isDesktop && <MobileSpacingContainer />}
        </CardBase>
      </CardContainerRight>
      <ScrollIconContainer
        buttonSize="md"
        showScrollToTop={showScrollToTop}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <ArrowUpIcon size="md" />
        <ScrollTextContainer>{t('scroll_to_top')}</ScrollTextContainer>
      </ScrollIconContainer>
    </DefinitionsContainer>
  );
};

const DefinitionsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
`;
const CardContainerLeft = styled.div`
  position: sticky;
  top: 108px;
  flex: 3;
  align-self: flex-start;
  height: auto;
  margin-bottom: 40px;
`;
const CardContainerRight = styled.div`
  flex: 7;
  margin-bottom: 40px;
`;

const ScrollIconContainer = styled(SecondaryButton)<{
  showScrollToTop: boolean;
}>`
  display: flex;
  position: fixed;
  right: 40px;
  bottom: 56px;
  box-shadow: 0 2px 10px #0003;
  opacity: ${(props) => (props.showScrollToTop ? 1 : 0)};
  visibility: ${(props) => (props.showScrollToTop ? 'visible' : 'hidden')};
  transition: all 0.2s ease;

  @media (max-width: ${MediaQueries.desktop}) {
    padding: 10px 10px;
    padding-left: 14px;
    border-radius: 30px;
    right: 16px;
    bottom: calc(${HEADER_HEIGHT_PX}px + 16px + env(safe-area-inset-bottom));
  }
`;

const ScrollTextContainer = styled.span`
  display: block;
  @media (max-width: ${MediaQueries.desktop}) {
    display: none;
  }
`;

const MobileSpacingContainer = styled.div`
  height: 100px;
`;
