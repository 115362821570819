import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { HEADER_HEIGHT_PX } from '../../../constants/Sizes';
import { Colors } from '../../../styles/colors';

type Props = {
  isLeftContainer: boolean;
  shouldAlignCenter?: boolean;
} & PropsWithChildren;

export const PracticeContainer = ({
  children,
  isLeftContainer,
  shouldAlignCenter = true,
}: Props) => (
  <Container
    isLeftContainer={isLeftContainer}
    shouldAlignCenter={shouldAlignCenter}
  >
    <Flex shouldAlignCenter={shouldAlignCenter}>{children}</Flex>
  </Container>
);

const Container = styled.div<{
  isLeftContainer: boolean;
  shouldAlignCenter: boolean;
}>`
  display: grid;
  grid-template-rows: ${({ shouldAlignCenter }) =>
    shouldAlignCenter ? '1fr auto 1fr' : 'auto'};
  padding: ${HEADER_HEIGHT_PX}px 20px;
  box-sizing: border-box;

  ${({ isLeftContainer }) =>
    isLeftContainer &&
    `margin-top: calc(${HEADER_HEIGHT_PX}px + env(safe-area-inset-top));
    background-color: ${Colors.backgroundColor};`}

  @media ${HookMediaQueries.isDesktop} {
    height: calc(100vh - ${HEADER_HEIGHT_PX}px);
    overflow-y: auto;
    padding: 72px 12.5%;
    ${({ isLeftContainer }) => isLeftContainer && `margin-top: 0;`}
  }
`;

const Flex = styled.div<{ shouldAlignCenter: boolean }>`
  display: flex;
  flex-direction: column;
  grid-row: ${({ shouldAlignCenter }) => (shouldAlignCenter ? '2' : '1')};
`;
