import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { useEffect } from 'react';

import { Platform } from '../constants/platforms';

const GOOGLE_SCOPE_EMAIL = 'email';
const GOOGLE_SCOPE_PROFILE = 'profile';

export const useInitializeGoogleSignIn = () => {
  const getClientId = () =>
    Capacitor.getPlatform() === Platform.ios
      ? process.env.REACT_APP_GOOGLE_IOS_CLIENT_ID
      : process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const initializeGoogleSignIn = async () => {
    await GoogleAuth.initialize({
      clientId: getClientId(),
      scopes: [GOOGLE_SCOPE_EMAIL, GOOGLE_SCOPE_PROFILE],
      grantOfflineAccess: true,
    });
  };

  useEffect(() => {
    initializeGoogleSignIn();
  }, []);
};
