import '../../../config/pdfjsWorker';
import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useRef, useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { config } from '../../../config';
import { HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT } from '../../../constants/Sizes';
import { Messages } from '../../../global.d';
import { OldExamPeriod } from '../../../models/OldExams';
import { useGetOldExamsQuery } from '../../../services/subjectsApi';
import { TextStyles } from '../../../styles/textStyles';
import { SecondaryButton } from '../../common/Button';
import { CardBase } from '../../common/CardBase';
import { Loader } from '../../common/Loader';
import { ArrowBackIcon } from '../../Icons/ArrowBackIcon';

import { ExamPeriod } from './OldExamDetailsComponent';

export const OldExamDocumentComponent = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { encodedSubjectName, examYear, examPeriod, oldExamDocument } =
    useParams();
  const { data: oldExams } = useGetOldExamsQuery(
    encodedSubjectName ?? skipToken
  );
  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [numPages, setNumPages] = useState<number>(0);

  useEffect(() => {
    const updateWidth = () => {
      if (pdfContainerRef.current) {
        setContainerWidth(pdfContainerRef.current.clientWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const selectedOldExam = oldExams?.find(
    (exam) => exam.year === Number(examYear)
  );

  const getSelectedPeriod = () => {
    if (examPeriod && selectedOldExam) {
      if (examPeriod === ExamPeriod.firstPeriod) {
        return selectedOldExam.firstPeriod;
      }
      if (examPeriod === ExamPeriod.secondPeriod) {
        return selectedOldExam.secondPeriod;
      }
    }
  };

  const selectedPeriod = getSelectedPeriod();
  const selectedDocument =
    oldExamDocument &&
    selectedPeriod &&
    selectedPeriod[oldExamDocument as keyof OldExamPeriod];

  const documentUrl = `${config.API_BASE_URL}${selectedDocument}`;

  const onReturnPress = () => {
    navigate(-1);
  };

  const onDownloadPress = () => {
    window.open(documentUrl, '_blank');
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={
        <HeaderContent>
          <NavigationContainer onClick={onReturnPress}>
            <ArrowBackIcon />
            <ExamTextContainer>
              {t('exam')} {t(oldExamDocument as keyof Messages)}{' '}
              {t('school_period', { count: examPeriod })} {examYear}
            </ExamTextContainer>
          </NavigationContainer>
          <StyledSecondaryButton buttonSize="md" onClick={onDownloadPress}>
            {t('download')}
          </StyledSecondaryButton>
        </HeaderContent>
      }
    >
      <PDFContainer ref={pdfContainerRef}>
        <Document
          file={documentUrl}
          loading={<Loader />}
          error={() => <p>{t('exam_document_not_available')}</p>}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <AllPagesContainer>
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={containerWidth * 0.95}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                error={() => null}
                loading={() => null}
              />
            ))}
          </AllPagesContainer>
        </Document>
      </PDFContainer>
    </CardBase>
  );
};

const StyledSecondaryButton = styled(SecondaryButton)`
  ${TextStyles.ButtonTextExtraSmallText}
`;

const HeaderContent = styled.div`
  ${TextStyles.OldExamsBase}
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const NavigationContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const ExamTextContainer = styled.span`
  margin: 0 5px;
`;

const PDFContainer = styled.div`
  height: calc(100vh - ${HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT});
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  justify-content: center;
`;

const AllPagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;
