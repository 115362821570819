import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutePathNames } from '../constants/routes';

const HistoryContext = createContext({
  history: [] as string[],
  addToHistory: (path: string): void => {
    /* necessary for context */
  },
  clearPathHistory: (path: string): void => {
    /* necessary for context */
  },
  navigateBackFromExamChallenge: (): void => {
    /* necessary for context */
  },
  navigateBackFromSummary: (): void => {
    /* necessary for context */
  },
});

// Keeps track of the route history of the user
export const HistoryProvider = ({ children }: { children: ReactNode }) => {
  const [history, setHistory] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Add the current route to history on location change
    setHistory((prev) => [...prev, location.pathname]);
  }, [location]);

  const addToHistory = (path: string) => {
    setHistory((prev) => [...prev, path]);
  };

  const clearPathHistory = (path: string) => {
    setHistory((prev) => prev.filter((path) => !path.includes(path)));
  };

  const navigateBackFromExamChallenge = () => {
    const amountOfPracticeRoutes =
      history
        .slice(0, -1)
        .reverse()
        .findIndex(
          (route) =>
            !route.includes(AppRoutePathNames.Practice) &&
            !route.includes(AppRoutePathNames.Result)
        ) + 1;

    const stepsBack = Math.max(amountOfPracticeRoutes, 1);

    clearPathHistory(AppRoutePathNames.Practice);
    clearPathHistory(AppRoutePathNames.Result);
    navigate(-stepsBack);
  };

  const navigateBackFromSummary = () => {
    const amountOfSummaryRoutes =
      history
        .slice(0, -1)
        .reverse()
        .findIndex((route) => !route.includes(AppRoutePathNames.Summary)) + 1;

    clearPathHistory(AppRoutePathNames.Summary);

    const stepsBack = Math.max(amountOfSummaryRoutes, 1);

    navigate(-stepsBack);
  };

  return (
    <HistoryContext.Provider
      value={{
        history,
        addToHistory,
        clearPathHistory,
        navigateBackFromExamChallenge,
        navigateBackFromSummary,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistoryContext = () => useContext(HistoryContext);
