import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { BuyableType } from '../../../../constants/buyableType';
import { ModalTypes, useModal } from '../../../../hooks/useModal';
import {
  Chapter,
  Section,
  Sections,
  Video,
} from '../../../../models/Summary/summary';

import { useReadTracker } from './useReadTracker';

const SECTION_SEARCH_PARAM = 'section';

export type CurrentSummarySegment = {
  chapter: Chapter;
  section: Section;
  videos: Video[];
};

type Props = {
  summary: Sections;
  onNextSection: () => void;
};

export const useSummaryData = ({ summary, onNextSection }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { encodedSubjectName } = useParams() || {};
  const querySection = searchParams.get(SECTION_SEARCH_PARAM);
  const { startTracking } = useReadTracker();
  const { showModal } = useModal();

  const getVideos = (section: Section) => {
    return section.topics?.map((topic) => topic.video).filter(Boolean);
  };

  const getCurrentSection = () => {
    for (const chapter of summary.chapters) {
      const foundSection = chapter.sections.find(
        (section) => section.encodedSectionName === querySection
      );

      if (foundSection) {
        return {
          chapter: chapter,
          section: foundSection,
          videos: getVideos(foundSection),
        };
      }
    }

    return {
      chapter: summary.chapters[0],
      section: summary.chapters[0].sections[0],
      videos: getVideos(summary.chapters[0].sections[0]),
    };
  };

  const [current, setCurrent] =
    useState<CurrentSummarySegment>(getCurrentSection());

  useEffect(() => {
    startTracking(current.chapter, current.section);
  }, [current]);

  useEffect(() => {
    setCurrent(getCurrentSection());
  }, [querySection]);

  const getNextSection = (): Section | undefined => {
    const currentSectionIndex = current.chapter.sections.indexOf(
      current.section
    );

    const nextSectionIfExists =
      current.chapter.sections[currentSectionIndex + 1];

    const currentChapterIndex = summary.chapters.indexOf(current.chapter);
    const nextChapterIfExists = summary.chapters[currentChapterIndex + 1];

    if (currentSectionIndex > -1) {
      if (nextSectionIfExists) {
        return nextSectionIfExists;
      } else {
        return nextChapterIfExists?.sections[0];
      }
    }
    return undefined;
  };

  const onSelectNextTopicClick = () => {
    const nextSection = getNextSection();

    if (nextSection) {
      if (!userHasAccessToSection(nextSection)) {
        showModal({
          type: ModalTypes.BuySectionModal,
          data: {
            encodedSubjectName,
            buyableType: BuyableType.Summary,
          },
        });
        return;
      }

      navigateToSection(nextSection);
    }
  };

  const setQueryParam = (section: Section) => {
    searchParams.set(SECTION_SEARCH_PARAM, section.encodedSectionName);
    setSearchParams(searchParams);
  };

  const navigateToSection = (nextSection: Section) => {
    onNextSection();
    setQueryParam(nextSection);
  };

  const userHasAccessToSection = (section: Section): boolean => {
    return (
      !summary.previewSummary ||
      summary.unlockedExplanationVideos ||
      section.preview
    );
  };

  return {
    current,
    onSelectNextTopicClick,
    getNextSection,
    navigateToSection,
  };
};
