import { isEmpty } from 'lodash';

import { BadgeProgress, UserBadge } from '../../models/Badge';

export const useBadgeProgress = (badge: UserBadge | undefined) => {
  const progress = badge?.progress.reduce((acc, curr) => {
    if (isEmpty(acc)) return curr;
    if (acc.amount !== acc.badgeGoal.targetAmount) {
      return acc;
    }
    return {
      ...acc,
      amount: acc.amount + curr.amount,
      badgeGoal: {
        ...curr.badgeGoal,
        targetAmount: acc.badgeGoal.targetAmount + curr.badgeGoal.targetAmount,
      },
    };
  }, {} as BadgeProgress);

  return progress;
};
