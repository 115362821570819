import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { usePrice } from '../../hooks/usePrice';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

import { DiscountSolidLabel } from './DiscountSolidLabel';

type Props = {
  price: number;
  oldPrice?: number;
  discountPercentage?: number;
  shouldDiscountFloatRight?: boolean;
  showNow?: boolean;
};

export const Price = ({
  price,
  oldPrice,
  discountPercentage,
  shouldDiscountFloatRight = false,
  showNow = false,
}: Props) => {
  const t = useTranslations();
  const { priceNumberToString } = usePrice();

  return (
    <PriceContainer>
      {!!oldPrice && <OldPrice>{priceNumberToString(oldPrice)}</OldPrice>}
      <CurrentPrice>
        {showNow && t('now')} {priceNumberToString(price)}
      </CurrentPrice>
      {shouldDiscountFloatRight && !!discountPercentage && <Flex1 />}
      {!!discountPercentage && (
        <DiscountSolidLabel discount={discountPercentage} />
      )}
    </PriceContainer>
  );
};

const PriceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  flex-direction: row;
  padding-bottom: 12px;
  flex: 1;
`;

const CurrentPrice = styled.span`
  color: ${Colors.primaryButtonFill};
  ${TextStyles.PriceBigText}
`;

const OldPrice = styled.span`
  text-decoration: line-through;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.PriceSmallText}
`;

const Flex1 = styled.div`
  flex: 1;
`;
