import { IconProps, Icon } from './Icon';

export const BadgeBronze = ({ color, ...props }: IconProps) => (
  <Icon {...props} width="70" height="39" viewBox="0 0 70 39" fill="none">
    <path
      d="M0.5 8.00001C0.5 3.85787 3.85787 0.5 8 0.5H62C66.1421 0.5 69.5 3.85786 69.5 8V22.8039C69.5 26.1383 67.2986 29.0724 64.0972 30.0048L37.0972 37.8684C35.7275 38.2673 34.2725 38.2673 32.9028 37.8684L5.90279 30.0048C2.70139 29.0724 0.5 26.1384 0.5 22.8039V8.00001Z"
      fill="white"
    />
    <path
      d="M0.5 8.00001C0.5 3.85787 3.85787 0.5 8 0.5H62C66.1421 0.5 69.5 3.85786 69.5 8V22.8039C69.5 26.1383 67.2986 29.0724 64.0972 30.0048L37.0972 37.8684C35.7275 38.2673 34.2725 38.2673 32.9028 37.8684L5.90279 30.0048C2.70139 29.0724 0.5 26.1384 0.5 22.8039V8.00001Z"
      fill="#FCC299"
    />
    <path
      d="M0.5 8.00001C0.5 3.85787 3.85787 0.5 8 0.5H62C66.1421 0.5 69.5 3.85786 69.5 8V22.8039C69.5 26.1383 67.2986 29.0724 64.0972 30.0048L37.0972 37.8684C35.7275 38.2673 34.2725 38.2673 32.9028 37.8684L5.90279 30.0048C2.70139 29.0724 0.5 26.1384 0.5 22.8039V8.00001Z"
      fill="white"
      fillOpacity="0.4"
    />
    <path
      d="M0.5 8.00001C0.5 3.85787 3.85787 0.5 8 0.5H62C66.1421 0.5 69.5 3.85786 69.5 8V22.8039C69.5 26.1383 67.2986 29.0724 64.0972 30.0048L37.0972 37.8684C35.7275 38.2673 34.2725 38.2673 32.9028 37.8684L5.90279 30.0048C2.70139 29.0724 0.5 26.1384 0.5 22.8039V8.00001Z"
      stroke="#B97C3F"
    />
  </Icon>
);
