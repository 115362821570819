import styled from '@emotion/styled';
import { ComponentProps } from 'react';

import { BuyableType } from '../../../constants/buyableType';
import { MediaQueries } from '../../../constants/MediaQueries';
import { Colors } from '../../../styles/colors';
import { BuyableItem } from '../../dashboard/BuyableItem';

import { useRecommendedBundles } from './hooks/useRecommendedBundles';

type Props = ComponentProps<'div'> & {
  buyableTypes: BuyableType[] | undefined;
  subjectId: string;
};

export const SummaryBuyables = ({
  buyableTypes,
  subjectId,
  ...props
}: Props) => {
  const recommendations = useRecommendedBundles(subjectId, buyableTypes) ?? [];
  return (
    <ContentWrapper {...props}>
      {recommendations.length > 0 && (
        <Container>
          {recommendations.map((buyableProduct, i) => (
            <StyledBuyableItem key={i} productBundle={buyableProduct} />
          ))}
        </Container>
      )}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  border: 1px solid ${Colors.dividerColor};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${MediaQueries.desktop}) {
    width: 960px;
  }
`;
const StyledBuyableItem = styled(BuyableItem)`
  flex: 1;
`;
