import { browserName, osName } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';

export type DeviceInfo = {
  identifier: string;
  description: string;
  platform: string;
  lastLogin?: Date;
};

const STORAGE_KEY_DEVICE_IDENTIFIER = 'deviceIdentifier';
const WEBKIT = 'WebKit';
const APP = 'App';

export const useDevice = (): DeviceInfo => {
  /*
   * Android and iOS will display 'WebKit' (Android 'Chrome WebKit') as the browser name, so we need to display 'App' instead
   * A user will then see in their connected devices 'iOS - App' when using the app
   * and 'iOS - Safari' when using the browser
   */
  const adjustedBrowserName = browserName.includes(WEBKIT) ? APP : browserName;

  const identifier = () => {
    const storedIdentifier = localStorage.getItem(
      STORAGE_KEY_DEVICE_IDENTIFIER
    );
    if (storedIdentifier) {
      return storedIdentifier;
    } else {
      const newIdentifier = uuidv4();
      localStorage.setItem(STORAGE_KEY_DEVICE_IDENTIFIER, newIdentifier);
      return newIdentifier;
    }
  };

  const deviceInfo: DeviceInfo = {
    identifier: identifier(),
    description: adjustedBrowserName,
    platform: osName,
  };

  return deviceInfo;
};
