import { useState } from 'react';

import { useError } from '../../../../hooks/useError';
import { AnswerResponse } from '../../../../models/training/AnswerResponse';
import { AnswerState } from '../../../../models/training/answerState';
import { UserAnswer } from '../../../../models/training/UserAnswer';
import { usePostAnswerMutation } from '../../../../services/trainingApi';

export const SCORE_SET_BY_BACKEND = 0;

export const useAnswerQuestion = (
  thesisId: string,
  encodedSubjectName: string,
  toNextQuestion: () => void,
  addAnswer: (answer: AnswerResponse) => void
) => {
  const [startTime, setStartTime] = useState(new Date());
  const [postAnswer] = usePostAnswerMutation();
  const { showErrorModal } = useError();

  const answerQuestion = async (
    answer: string,
    questionId: string,
    scoredPoints: number
  ) => {
    const userAnswer = constructUserAnswer(answer, scoredPoints);
    try {
      const answer = await postAnswer({
        questionId: questionId,
        body: userAnswer,
      }).unwrap();

      addAnswer(answer);
      setStartTime(new Date());
      toNextQuestion();
    } catch (error) {
      showErrorModal(error);
    }
  };

  const constructUserAnswer = (
    answer: string,
    scoredPoints: number
  ): UserAnswer => {
    const endTime = new Date();

    return {
      thesisId,
      userAnswer: answer,
      encodedSubjectName,
      state: AnswerState.UNANSWERED,
      startDate: startTime.toString(),
      endDate: endTime.toString(),
      scoredPoints,
    };
  };

  return { answerQuestion };
};
