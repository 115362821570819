import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../constants/MediaQueries';
import { subjectRoutes } from '../../constants/routes';
import { useGetSubjectByEncodedNameQuery } from '../../services/subjectsApi';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import {
  getFirstUpcomingExamDate,
  getSubjectDateString,
} from '../../utils/dateUtils';
import { SubjectImage } from '../common/SubjectImage';
import { SubNavigationMenu } from '../navbar/SubNavigationMenu';

export const SubjectHeader = () => {
  const t = useTranslations();
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const { encodedSubjectName } = useParams();
  const { data: subject } = useGetSubjectByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );

  const dateString = subject
    ? getSubjectDateString(getFirstUpcomingExamDate(subject), t)
    : '';

  return (
    <Container isMobile={!isDesktop}>
      <SubjectContainer>
        <SubjectImage src={subject?.subjectImage} />
        <TextContainer>
          <Title>{subject?.name}</Title>
          <Text>{dateString}</Text>
        </TextContainer>
      </SubjectContainer>

      <SubNavigationMenu routes={subjectRoutes} />
    </Container>
  );
};
const Container = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => isMobile && 'padding: 0 20px;'}
`;
const SubjectContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 36px 0 24px 0;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
`;
const Title = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.SubjectHeader}
`;
const Text = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.SubjectSubheader}
`;
