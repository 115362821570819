import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { ComponentProps, PropsWithChildren, ReactElement } from 'react';

import { HookMediaQueries } from '../../constants/MediaQueries';
import { Colors } from '../../styles/colors';

import { CardBaseHeader } from './CardBaseHeader';
import { CardContainer } from './CardContainer';

export const enum FooterAlignment {
  'left',
  'right',
  'space-between',
}
export const enum RenderStyle {
  'mobile',
  'desktop',
  'automatic',
}

type Props = PropsWithChildren<{
  footerAlignment?: FooterAlignment;
  title?: string;
  header?: ReactElement | string;
  footerButtons?: ReactElement;
  fullWidth?: boolean;
  fullWidthBody?: boolean;
  fullHeightBody?: boolean;
  renderStyle?: RenderStyle;
  bodyOverflowY?: string;
}> &
  ComponentProps<'div'>;

export const CardBase = ({
  children,
  header,
  footerButtons,
  fullWidth,
  fullWidthBody = false,
  fullHeightBody = false,
  footerAlignment = FooterAlignment['space-between'],
  renderStyle = RenderStyle.automatic,
  bodyOverflowY = 'auto',
  ...props
}: PropsWithChildren<Props>) => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);

  const shouldSetDesktopStyle = () => {
    if (renderStyle !== RenderStyle.automatic) {
      return renderStyle === RenderStyle.desktop;
    }
    return isDesktop;
  };

  return (
    <CardContainer renderStyle={renderStyle} fullWidth={fullWidth} {...props}>
      {header && <CardBaseHeader>{header}</CardBaseHeader>}
      <CardBody
        innerFullWidth={fullWidthBody}
        innerFullHeight={fullHeightBody}
        overflowY={bodyOverflowY}
      >
        {children}
      </CardBody>
      {footerButtons && (
        <CardFooter alignment={footerAlignment}>{footerButtons}</CardFooter>
      )}
    </CardContainer>
  );
};

const CardBody = styled.div<{
  innerFullWidth: boolean;
  innerFullHeight: boolean;
  overflowY: string;
}>`
  ${({ innerFullWidth }) => !innerFullWidth && 'padding: 20px;'}
  ${({ innerFullHeight }) => innerFullHeight && 'height: 100%;'}
  ${({ overflowY }) => `overflow-y: ${overflowY};`}
`;
const CardFooter = styled.div<{
  alignment: FooterAlignment;
  isDesktop?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 8px;
  border-top: 1px solid ${Colors.inputBorderColor};
  ${({ alignment }) =>
    alignment === FooterAlignment['space-between'] &&
    'justify-content: space-between;'}
  ${({ alignment }) =>
    alignment === FooterAlignment.left && 'justify-content: flex-start'}
      ${({ alignment }) =>
    alignment === FooterAlignment.right && 'justify-content: flex-end;'}
`;
