export enum ApiTagTypes {
  Subjects = 'Subjects',
  Sections = 'Sections',
  Definition = 'Definition',
  Tips = 'Tips',
  Login = 'Login',
  Exists = 'Exists',
  Exercises = 'Exercises',
  Box = 'Box',
  ProductBundlesRecommendation = 'ProductBundlesRecommendation',
  ProductBundles = 'ProductBundles',
  Videos = 'Videos',
  Results = 'Results',
  User = 'User',
  UserSubjects = 'UserSubjects',
  Schools = 'Schools',
  Training = 'Training',
  Summary = 'Summary',
  Thesis = 'Thesis',
  Devices = 'Devices',
  UserProducts = 'UserProducts',
  ExperimentSections = 'ExperimentSections',
  TopicDetails = 'TopicDetails',
}

export const LOGIN_ENDPOINT = 'login';
export const COURSE_LEVELS_ENDPOINT = 'getCourseLevels';
export const COURSE_LEVEL_SUBJECTS_ENDPOINT = 'getSubjectsByCourseLevel';
export const REGISTER_ENDPOINT = 'register';
export const RESEND_EMAIL_VERIFICATION_ENDPOINT = 'resendEmailVerification';
export const VERIFY_EMAIL_ENDPOINT = 'verifyEmail';

export const authorizationFreeEndpoints = [
  LOGIN_ENDPOINT,
  COURSE_LEVELS_ENDPOINT,
  REGISTER_ENDPOINT,
  VERIFY_EMAIL_ENDPOINT,
  COURSE_LEVEL_SUBJECTS_ENDPOINT,
  RESEND_EMAIL_VERIFICATION_ENDPOINT,
];

export const STORE_URL = 'https://examenoverzicht.nl/';

export const APPLE_LOGIN_CLIENT_ID = 'nl.examenoverzicht.examenoverzicht';
export const GOOGLE_USERINFO_URL =
  'https://www.googleapis.com/oauth2/v1/userinfo';
