import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { PropsWithChildren } from 'react';

import { HookMediaQueries } from '../../constants/MediaQueries';
import { Colors } from '../../styles/colors';

import { RenderStyle } from './CardBase';

type Props = {
  fullWidth?: boolean;
  renderStyle?: RenderStyle;
};

export const CardContainer = ({
  children,
  renderStyle = RenderStyle.automatic,
  fullWidth,
  ...props
}: PropsWithChildren<Props>) => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);

  const shouldSetDesktopStyle = () => {
    if (renderStyle !== RenderStyle.automatic) {
      return renderStyle === RenderStyle.desktop;
    }
    return isDesktop;
  };

  return (
    <Card isDesktop={shouldSetDesktopStyle()} fullWidth={fullWidth} {...props}>
      {children}
    </Card>
  );
};

const Card = styled.div<{ fullWidth?: boolean; isDesktop?: boolean }>`
  background-color: ${Colors.modalBackgroundColor};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 24px;
  ${({ isDesktop }) =>
    isDesktop &&
    `
    box-shadow: 0px 2px 2px 0px ${Colors.shadowColor};
    border-radius: 8px;
    border: 1px solid ${Colors.dividerColor};
    `}
  ${({ fullWidth }) => !fullWidth && 'width: min-content;'}
`;
