export type Version = {
  versionState: VersionState;
  maximumFlexibleVersion: string;
};

export enum VersionState {
  Flexible = 'FLEXIBLE',
  Immediate = 'IMMEDIATE',
  Valid = 'VALID',
}
