import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { CardBase } from '../../components/common/CardBase';
import { ApiTagTypes } from '../../constants/api';
import { appRoutes } from '../../constants/routes';
import { Messages } from '../../global.d';
import { useInvalidateTags } from '../../hooks/useInvalidateTags';
import { PaymentStatus } from '../../models/payments';
import { useLazyGetPaymentStatusQuery } from '../../services/paymentApi';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearCart } from '../../store/slices/cartSlice';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { PrimaryButton } from '../common/Button';
import { CloseCircleIcon } from '../Icons/CloseCircleIcon';
import { DollarCircleIcon } from '../Icons/DollarCircleIcon';
import { SuccessCircleIcon } from '../Icons/SuccessCircleIcon';

const INTERVAL_IN_MILLISECONDS = 5000;
const ICON_SIZE = '160px';

/*
  A better solution would be to use a websocket to listen for events on the payment status. 
  Request in ticket: https://digitalclub.atlassian.net/browse/EXO-4239
*/
export const PaymentStatusCard = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const invalidateTags = useInvalidateTags();
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(
    PaymentStatus.open
  );
  const { paymentId } = useAppSelector((state) => state.cart);
  const statusTranslationKey: keyof Messages = `payment_status_${paymentStatus}`;

  const [getPaymentStatusTrigger] = useLazyGetPaymentStatusQuery();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    getPaymentStatus();

    if (paymentStatus === PaymentStatus.open) {
      intervalId = setInterval(async () => {
        await getPaymentStatus();

        if (paymentStatus !== PaymentStatus.open) {
          clearInterval(intervalId);
        }
      }, INTERVAL_IN_MILLISECONDS);
    }

    return () => clearInterval(intervalId);
  }, [paymentId, paymentStatus]);

  const getPaymentStatus = async () => {
    if (paymentId) {
      const payment = await getPaymentStatusTrigger({ paymentId }).unwrap();
      if (payment.status && payment.status !== PaymentStatus.open) {
        setPaymentStatus(payment.status);

        if (payment.status === PaymentStatus.paid) {
          dispatch(clearCart());
          invalidateTags([
            ApiTagTypes.Sections,
            ApiTagTypes.Exercises,
            ApiTagTypes.ProductBundlesRecommendation,
            ApiTagTypes.ProductBundles,
            ApiTagTypes.Videos,
            ApiTagTypes.Summary,
            ApiTagTypes.UserProducts,
          ]);
        }
      }
    }
  };

  const getIcon = () => {
    if (paymentStatus === PaymentStatus.paid) {
      return <StyledSuccessCircleIcon color={Colors.topicPositive} />;
    } else if (paymentStatus === PaymentStatus.open) {
      return <StyledDollarCircleIcon color={Colors.primary} />;
    }
    return <StyledCloseCircleIcon color={Colors.topicNegative} />;
  };

  return (
    <StyledCardBase fullWidth={true}>
      {getIcon()}
      <Message>{t(statusTranslationKey)}</Message>
      <PrimaryButton
        buttonSize="md"
        onClick={() => navigate(appRoutes.Dashboard)}
      >
        {t('back_to_dashboard')}
      </PrimaryButton>
    </StyledCardBase>
  );
};

const StyledCardBase = styled(CardBase)`
  width: 618px;
  text-align: center;
`;

const Message = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.BodyMedium};
  text-align: center;
`;

const StyledSuccessCircleIcon = styled(SuccessCircleIcon)`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
`;

const StyledDollarCircleIcon = styled(DollarCircleIcon)`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
`;
const StyledCloseCircleIcon = styled(CloseCircleIcon)`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
`;
