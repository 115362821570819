import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../../../constants/routes';
import { useError } from '../../../../hooks/useError';
import { useIsWeb } from '../../../../hooks/useIsWeb';
import { User } from '../../../../models/User';
import { UserRole } from '../../../../models/UserRole';
import {
  usePostSocialLoginMutation,
  Error,
} from '../../../../services/userApi';
import { useAppDispatch } from '../../../../store/hooks';
import {
  setSocialLoginProvider,
  setToken,
} from '../../../../store/slices/userSlice';
import { SocialProvider } from '../../../../utils/socialLoginUtils';
import { useDevice } from '../../hooks/useDevice';
import { useOnboarding } from '../../hooks/useOnboarding';
import { SocialUser } from '../SocialUser';

export const useSocialLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const device = useDevice();
  const { onboardSocialUser } = useOnboarding();
  const [loginUser] = usePostSocialLoginMutation();
  const { showErrorModal } = useError();
  const isWeb = useIsWeb();

  const prepareLoginBody = (socialUser: SocialUser) => {
    if (socialUser.provider === SocialProvider.apple) {
      return {
        ...socialUser,
        code: socialUser.serverAuthCode,
        device,
      };
    }

    return {
      ...socialUser,
      accessToken: socialUser.token,
      device,
    };
  };

  const handleSuccessfulLogin = (user: User, socialUser: SocialUser) => {
    storeUserInfo(user, socialUser.provider);

    if (!checkIfHasOnboarded(user)) {
      onboardSocialUser(socialUser);
      return;
    }

    if (user.accountType === UserRole.teacher && isWeb) {
      window.location.href = appRoutes.TeacherDashboard;
    } else {
      navigate(appRoutes.Dashboard);
    }
  };

  const loginToAPI = async (socialUser: SocialUser): Promise<Error | void> => {
    try {
      const body = prepareLoginBody(socialUser);
      const result = await loginUser({ provider: socialUser.provider, body });

      if (result.error) throw result.error;

      const user = result.data;
      handleSuccessfulLogin(user, socialUser);
    } catch (error) {
      showErrorModal(error);
    }
  };

  const checkIfHasOnboarded = (user: User): boolean => {
    const hasStudentCourseLevel = !!user.courseLevel;
    const hasTeacherCourseLevels = user.teacherCourseLevels.length > 0;
    const hasSubjects = user.subjects.length > 0;

    return hasStudentCourseLevel || hasTeacherCourseLevels || hasSubjects;
  };

  const storeUserInfo = (user: User, provider: SocialProvider) => {
    dispatch(setSocialLoginProvider(provider));
    dispatch(setToken(user.token));
  };

  return { loginToAPI };
};
