import styled from '@emotion/styled';
import { useState } from 'react';

import { AnswerResponse } from '../../../models/training/AnswerResponse';
import { QuestionType } from '../../../models/training/Question';
import { Thesis } from '../../../models/training/Thesis';

import { useAnswerQuestion } from './hooks/useAnswerQuestion';
import { DictationQuestion } from './questions/DictationQuestion';
import { MultipleChoiceQuestion } from './questions/MultipleChoiceQuestion';
import { OpenQuestion } from './questions/OpenQuestion';

type Props = {
  thesis: Thesis;
  incrementQuestionCounter: () => void;
  encodedSubjectName: string;
  lastQuestionId?: string;
  showRating: () => void;
};

export const PracticeQuestions = ({
  thesis,
  incrementQuestionCounter,
  encodedSubjectName,
  lastQuestionId,
  showRating,
}: Props) => {
  const [answers, setAnswers] = useState<AnswerResponse[]>([]);
  const findIndex = thesis.questions.findIndex(
    (question) => question._id === lastQuestionId
  );
  const lastQuestionIndex = Math.max(findIndex, 0);
  const [currentThesisQuestion, setCurrentThesisQuestion] =
    useState(lastQuestionIndex);
  const [currentThesisId, setCurrentThesisId] = useState(thesis._id);

  if (currentThesisId !== thesis._id) {
    setCurrentThesisId(thesis._id);
    setCurrentThesisQuestion(lastQuestionIndex);
  }

  const addAnswer = (answer: AnswerResponse) => {
    setAnswers([...answers, answer]);
  };

  const toNextQuestion = () => {
    if (currentThesisQuestion === thesis.questions.length - 1) {
      showRating();
      return;
    }
    setCurrentThesisQuestion(currentThesisQuestion + 1);
    incrementQuestionCounter();
  };

  const { answerQuestion } = useAnswerQuestion(
    thesis?._id || '',
    encodedSubjectName,
    toNextQuestion,
    addAnswer
  );

  const questionsToBeRendered = thesis.questions.slice(
    lastQuestionIndex,
    currentThesisQuestion + 1
  );

  const hasValidAnswer = (questionId: string) => {
    const answer = answers.find((answer) => answer.questionId === questionId);
    return answer ? answer.scoredPoints > 0 : null;
  };

  return (
    <Container>
      {questionsToBeRendered.map((question) => {
        if (question.type === QuestionType.Open) {
          return (
            <OpenQuestion
              key={question._id}
              question={question}
              onAnswerQuestion={answerQuestion}
            />
          );
        }

        if (question.type === QuestionType.MultipleChoice) {
          return (
            <MultipleChoiceQuestion
              key={question._id}
              onAnswerQuestion={answerQuestion}
              question={question}
            />
          );
        }

        if (question.type === QuestionType.Dictation) {
          return (
            <DictationQuestion
              key={question._id}
              question={question}
              onAnswerQuestion={answerQuestion}
              validAnswer={hasValidAnswer(question._id)}
            />
          );
        }
      })}
    </Container>
  );
};

const Container = styled.div``;
