import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { Messages } from '../../../global.d';
import { PriceInformation } from '../../../models/PriceInformation';
import { Error } from '../../../services/baseApi';
import { useLazyValidateDiscountCodeQuery } from '../../../services/cartApi';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addDiscount, removeDiscount } from '../../../store/slices/cartSlice';
import { CardBase } from '../../common/CardBase';
import { InputFieldComponent } from '../../common/Input';

import { DiscountDetails } from './DiscountDetails';
import { FormData, FormFields, DiscountFormSchema } from './DiscountFormSchema';

type Props = {
  priceInformation: PriceInformation | undefined;
};

export const DiscountCard = ({ priceInformation }: Props) => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const [validateDiscountCodeTrigger] = useLazyValidateDiscountCodeQuery();
  const cart = useAppSelector((state) => state.cart);
  const hasDiscount = cart.discountCode !== undefined;

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(DiscountFormSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      [FormFields.discountCode]: cart.discountCode || '',
    },
  });

  const { ref: discountCodeRef, ...discountCodeRegister } = register(
    FormFields.discountCode
  );

  const onDiscountSubmit = async (discountCode: string) => {
    clearErrors(FormFields.discountCode);

    if (cart.items.length === 0) {
      setErrorMessage(t('discount_no_products'));
      dispatch(removeDiscount());
      return;
    }

    try {
      await validateDiscountCodeTrigger({
        code: discountCode,
        productBundleIds: cart.items.map((item) => item._id),
      }).unwrap();

      dispatch(addDiscount(discountCode));
    } catch (err) {
      const error = err as Error;
      switch (error?.status) {
        case 403:
          setErrorMessage(t('discount_rule_not_active'));
          break;
        case 404:
          setErrorMessage(t('discount_not_found'));
          break;
        case 406:
          setErrorMessage(
            `${t('discount_not_applicable', {
              minimumAmount: error.data.message,
            })}`
          );
          break;
        case 409:
          setErrorMessage(t('discount_used'));
          break;
        default:
          setErrorMessage(t('discount_unknown_error'));
      }
      dispatch(removeDiscount());
    }
  };

  useEffect(() => {
    const discountCode = watch(FormFields.discountCode);
    if (discountCode) {
      onDiscountSubmit(discountCode);
    }
  }, [cart.items]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data: { discountCode: string }) => {
    onDiscountSubmit(data.discountCode);
  };

  const onRemoveDiscount = () => {
    dispatch(removeDiscount());
    reset({ [FormFields.discountCode]: '' });
  };

  const setErrorMessage = (messageKey: string) => {
    setError(FormFields.discountCode, {
      type: 'manual',
      message: messageKey,
    });
  };

  const getErrorText = (error?: FieldError) =>
    error?.message !== undefined ? t(error.message as keyof Messages) : '';

  return (
    <CardBase fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldComponent
          label={t('discount_code')}
          type="text"
          hasError={errors.discountCode !== undefined}
          errorText={getErrorText(errors.discountCode)}
          showAcceptButton={!hasDiscount}
          showRemoveButton={hasDiscount}
          acceptButtonTranslationKey="use"
          onRemove={onRemoveDiscount}
          {...discountCodeRegister}
          inputRef={discountCodeRef}
        />
        {priceInformation && (
          <DiscountDetails priceInformation={priceInformation} />
        )}
      </form>
    </CardBase>
  );
};
