import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetExamTrainingVideoQuery } from '../../../services/subjectsApi';
import { Colors } from '../../../styles/colors';
import { Loader } from '../../common/Loader';
import { VideoPlayer } from '../../common/VideoPlayer';
import { Page } from '../../Page';
import { Locked } from '../summary/Locked';

import { ExamTrainingVideoHeader } from './ExamTrainingVideoHeader';

export const ExamTrainingVideo = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { encodedSubjectName } = useParams();
  const {
    data: livestream,
    error,
    isLoading,
  } = useGetExamTrainingVideoQuery(encodedSubjectName ?? skipToken);

  const handleClose = () => {
    navigate(-1);
  };

  const errorStatus = error && 'status' in error && error.status;

  return (
    <Container>
      <ExamTrainingVideoHeader
        title={livestream?.subjectName || ''}
        description={t('livestream_rewatch')}
        onClose={handleClose}
      />
      <Page>
        {errorStatus === 403 && (
          <Locked lockedMessage={t('exam_training_video_locked')} />
        )}
        {isLoading && <Loader />}
        {livestream && <VideoPlayer videoUrl={livestream?.videoUrl || ''} />}
      </Page>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  background-color: ${Colors.modalBackgroundColor};
`;
