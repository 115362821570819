import styled from '@emotion/styled';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { appRoutes } from '../../constants/routes';
import { useGetUserQuery } from '../../services/userApi';
import { Colors } from '../../styles/colors';
import { PrimaryButton, SecondaryButton } from '../common/Button';
import { ProfileImageRound } from '../common/ProfileImageRound';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';

import { ProfileMenu } from './ProfileMenu';

export const ProfileButton = () => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { data: user } = useGetUserQuery();
  const location = useLocation();
  const isProfilePage = location.pathname.includes(appRoutes.Profile);

  const arrowIconColor = isProfilePage
    ? Colors.primaryTextInverted
    : Colors.primaryIconColor;

  const renderButtonBody = () => {
    return (
      <ButtonContainer>
        <ProfileImageRound src={user?.profileImage} />
        <RotatingIcon isExpanded={showProfileMenu}>
          <ArrowDownIcon color={arrowIconColor} />
        </RotatingIcon>
      </ButtonContainer>
    );
  };

  return (
    <Container>
      {isProfilePage ? (
        <ButtonPrimary
          buttonSize="md"
          onClick={() => setShowProfileMenu(!showProfileMenu)}
        >
          {renderButtonBody()}
        </ButtonPrimary>
      ) : (
        <Button
          buttonSize="md"
          onClick={() => setShowProfileMenu(!showProfileMenu)}
        >
          {renderButtonBody()}
        </Button>
      )}
      {showProfileMenu && (
        <ProfileMenu onClose={() => setShowProfileMenu(false)} />
      )}
    </Container>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
const Button = styled(SecondaryButton)`
  padding: 4px 12px 4px 4px;
`;
const ButtonPrimary = styled(PrimaryButton)`
  padding: 4px 12px 4px 4px;
`;
const RotatingIcon = styled.div<{ isExpanded: boolean }>`
  transform: ${(props) =>
    props.isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s;
`;
const Container = styled.div`
  position: relative;
`;
