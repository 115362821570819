import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';

import { CartItemCard } from '../../components/cart/CartItemCard';
import { DiscountCard } from '../../components/cart/price-and-discount/DiscountCard';
import { ShippingAndPayment } from '../../components/cart/ShippingAndPayment';
import { CardBase } from '../../components/common/CardBase';
import { RecommendationCard } from '../../components/RecommendationCard';
import { HookMediaQueries } from '../../constants/MediaQueries';
import { HEADER_HEIGHT_PX } from '../../constants/Sizes';
import { PriceInformation } from '../../models/PriceInformation';
import { useGetPriceInformationQuery } from '../../services/cartApi';
import { useAppSelector } from '../../store/hooks';

export const Cart = () => {
  const cart = useAppSelector((state) => state.cart);

  const priceInformationEmptyObject: PriceInformation = {
    productBundles: [],
    discountPercentage: 0,
    discountPrice: 0,
    totalPrice: 0,
    shippingCostPrice: 0,
    discountedTotalPrice: 0,
    hasPhysicalProducts: false,
    discountCodePrice: 0,
    discountPercentagePrice: 0,
  };

  const { data: priceInformationResponse, isLoading } =
    useGetPriceInformationQuery(
      cart.items.length > 0
        ? {
            productBundleIds: cart.items.map((bundle) => bundle._id),
            discountCode: cart.discountCode,
          }
        : skipToken
    );

  const priceInformation = cart.items.length
    ? (priceInformationResponse ?? priceInformationEmptyObject)
    : priceInformationEmptyObject;

  return (
    <Container>
      <LeftContainer>
        <CartItemCard
          productBundles={priceInformation?.productBundles ?? []}
          isLoading={isLoading}
        />

        <DiscountCard priceInformation={priceInformation} />

        <RecommendationCard titleKey="interesting_products_for_you" />
      </LeftContainer>

      <ShippingFormContainer fullWidth={true}>
        <ShippingAndPayment priceInformation={priceInformation} />
      </ShippingFormContainer>
    </Container>
  );
};

const ShippingFormContainer = styled(CardBase)`
  flex: 1;
  position: sticky;
  top: ${HEADER_HEIGHT_PX + 20}px;
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;

  @media ${HookMediaQueries.isDesktop} {
    flex-direction: row;
  }
`;

const LeftContainer = styled.div`
  flex: 2;
`;
