import styled from '@emotion/styled';
import { CalendarEvent } from '@schedule-x/calendar';
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import Checkbox from '../../components/common/Checkbox';
import { allSubjectCategories } from '../../constants/constants';
import { SCHEDULE_DATE_TIME_FORMAT } from '../../constants/date';
import { LOCALE_SCHEDULE } from '../../constants/locale';
import { HookMediaQueries } from '../../constants/MediaQueries';
import { useWelcomingGift } from '../../hooks/useWelcomingGift';
import { Subject } from '../../models/Subject';
import { useGetUserSubjectsQuery } from '../../services/dashboardApi';
import { useGetSubjectsByCourseLevelQuery } from '../../services/onboardingApi';
import { useGetScheduleTimeSlotsQuery } from '../../services/subjectsApi';
import { useGetUserQuery } from '../../services/userApi';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

import { PeriodDropdown } from './PeriodDropdown';
import { ScheduleComponent } from './ScheduleComponent';
export const Schedule = () => {
  useWelcomingGift();
  const t = useTranslations();
  const { data: user } = useGetUserQuery();
  const { data: mySubjects = [] } = useGetUserSubjectsQuery();
  const { data: timeslots } = useGetScheduleTimeSlotsQuery();

  const courseLevel = user?.teacherCourseLevels?.[0] || user?.courseLevel;

  const { data: allSubjects = [] } = useGetSubjectsByCourseLevelQuery(
    {
      courseLevelEncoded: courseLevel || '',
      categories: allSubjectCategories,
    },
    {
      skip: !courseLevel,
    }
  );

  const [period, setPeriod] = useState<2 | 1>(1);
  const [showOnlyMySubjects, setShowOnlyMySubjects] = useState(true);

  const allSubjectsData = allSubjects.map((subject) => ({
    subject,
  }));

  const subjects = showOnlyMySubjects ? mySubjects : allSubjectsData;

  const firstExamDate = Date.parse(
    subjects?.[0]?.subject?.timeslots?.[period]?.startDate
  );

  const [firstDate, lastDate] = subjects.reduce(
    (acc, curr) => {
      const [accFirstDate, accLastDate] = acc;
      const newFirstDate = Math.min(
        accFirstDate,
        Date.parse(curr.subject.timeslots[period]?.startDate)
      );

      const newLastDate = Math.max(
        accLastDate,
        Date.parse(curr.subject.timeslots[period]?.endDate)
      );
      return [
        !isNaN(newFirstDate) ? newFirstDate : accFirstDate,
        !isNaN(newLastDate) ? newLastDate : accLastDate,
      ];
    },
    [firstExamDate, 0]
  );

  const formatDate = (date: Date) => {
    return format(date, SCHEDULE_DATE_TIME_FORMAT);
  };

  const getEvents = () => {
    return subjects
      .map((subject) => {
        const timeslot = subject.subject.timeslots[period];

        if (!timeslot) return;

        return {
          ...subject.subject,
          id: subject.subject._id,
          title: subject.subject.name,
          start: formatDate(new Date(timeslot.startDate)),
          end: formatDate(new Date(timeslot.endDate)),
        };
      })
      .filter((v) => v !== undefined);
  };

  const events = getEvents() as (CalendarEvent & Subject)[];

  return (
    <Container>
      <ScheduleHeaderContainer>
        <ScheduleInfoContainer>
          {t('schedule')} {courseLevel}{' '}
          {new Date(firstDate).toLocaleDateString(LOCALE_SCHEDULE, {
            year: 'numeric',
            month: 'short',
          })}
        </ScheduleInfoContainer>
        <ScheduleOptionsContainer>
          <ShowAllSubjectsCheckboxContainer>
            <Checkbox
              label={t('schedule_only_my_subjects')}
              checked={showOnlyMySubjects}
              onChange={(e) => setShowOnlyMySubjects(e.target.checked)}
            />
          </ShowAllSubjectsCheckboxContainer>
          <PeriodDropdownContainer>
            <PeriodDropdown
              onOptionClick={(value) => setPeriod(value as 2 | 1)}
              selectedPeriod={period}
              numberOfTimeslots={timeslots?.numberOfTimeslots}
            />
          </PeriodDropdownContainer>
        </ScheduleOptionsContainer>
      </ScheduleHeaderContainer>
      <ScheduleComponent
        //force update on events change
        key={`${period}${showOnlyMySubjects}${events}`}
        events={events}
        firstDate={firstDate}
        lastDate={lastDate}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 60px;
`;

const ScheduleHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px;
  background-color: ${Colors.inputBackgroundColor};
  height: 64px;

  @media ${HookMediaQueries.isDesktop} {
    padding: 5px 0;
  }
`;

const ScheduleOptionsContainer = styled.div`
  background-color: ${Colors.secondaryButtonFill};
  border: 1px solid ${Colors.secondaryButtonStroke};
  width: 245px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  padding: 0 10px;
  ${TextStyles.ScheduleOptionsText}
`;

const PeriodDropdownContainer = styled.div`
  height: 100%;
`;

const ShowAllSubjectsCheckboxContainer = styled.div`
  height: 100%;
  padding: 0px 5px;
  border-right: 1px solid;
`;

const ScheduleInfoContainer = styled.div`
  ${TextStyles.ScheduleInfoText}
`;
