import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { SocialProvider } from '../../../utils/socialLoginUtils';

import { SocialUser } from './SocialUser';

export const signInGoogle = async () => {
  try {
    const res = await GoogleAuth.signIn();

    const user: SocialUser = {
      provider: SocialProvider.google,
      token: res.authentication.accessToken,
    };

    return user;
  } catch (error) {
    console.error('Google sign-in error:', error);
    throw error;
  }
};

export const signOutGoogle = async () => {
  try {
    await GoogleAuth.signOut();
  } catch (err) {
    console.log('error while signing out Google account', err);
  }
};
