import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { usePrice } from '../../../hooks/usePrice';
import { PriceInformation } from '../../../models/PriceInformation';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

import { PriceRow } from './PriceRow';

type Props = {
  hasDiscount: boolean;
  priceInformation: PriceInformation;
};

export const TotalPriceRow = ({ hasDiscount, priceInformation }: Props) => {
  const t = useTranslations();
  const { priceNumberToString } = usePrice();

  return (
    <PriceRow>
      <TotalPrice>{t('total_price')}</TotalPrice>
      <TotalPriceContainer>
        {hasDiscount && (
          <OriginalPrice>
            {priceNumberToString(priceInformation.totalPrice)}
          </OriginalPrice>
        )}
        <TotalPrice>
          {priceNumberToString(priceInformation.discountedTotalPrice)}
        </TotalPrice>
      </TotalPriceContainer>
    </PriceRow>
  );
};

const TotalPrice = styled.h1`
  margin: 0;
  ${TextStyles.PriceBigText}
`;

const OriginalPrice = styled.h1`
  margin: 0;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.PriceSmallTextDiscount}
`;

const TotalPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;
