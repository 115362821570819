import styled from '@emotion/styled';
import { isEmpty } from 'lodash';

import { useBadgeProgress } from '../../components/dashboard/useBadgeProgress';
import { ModalTypes, useModal } from '../../hooks/useModal';
import { UserBadgeWithIcon } from '../../models/Badge';
import { TextStyles } from '../../styles/textStyles';

import { BadgeProgressComponent } from './BadgeProgressComponent';

export const BadgeComponent = ({ badge }: { badge: UserBadgeWithIcon }) => {
  const { showModal } = useModal();

  const onBadgeContainerPress = () => {
    showModal({
      type: ModalTypes.BadgeDetailsModal,
      data: { badge },
    });
  };

  const progress = useBadgeProgress(badge);

  return (
    <BadgeContainer onClick={onBadgeContainerPress}>
      <ImageContainer>
        <img src={badge.icon} />
      </ImageContainer>
      <BadgeNameContainer>{badge.badge.name}</BadgeNameContainer>
      {!isEmpty(progress) && <BadgeProgressComponent progress={progress} />}
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div`
  width: 200px;
  min-height: 200px;
  background-color: white;
  margin: 5px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 120px;
`;

const BadgeNameContainer = styled.p`
  text-align: center;
  ${TextStyles.CardTitleText}
`;
