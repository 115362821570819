import styled from '@emotion/styled';
import { ComponentProps } from 'react';

type Props = ComponentProps<'div'>;

export const Loader = ({ ...props }: Props) => {
  return (
    <LoaderContainer {...props}>
      <Dashes>
        <Dash />
        <Dash />
        <Dash />
        <LogoImage src="/assets/e-logo.svg" alt="Logo" />
      </Dashes>
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  margin: 20px;
`;

const Dashes = styled.div`
  width: 54px;
  height: 54px;
  margin: 20px auto;
  position: relative;
`;

const Dash = styled.span`
  height: 14px;
  width: 4px;
  float: left;
  margin: 0 7px;
  background-color: black;
  display: block;
  opacity: 0;

  &:nth-of-type(1) {
    transform: rotate(135deg);
    animation: 1s blink infinite 0.2s;
  }

  &:nth-of-type(2) {
    margin-top: -7px;
    transform: rotate(180deg);
    animation: 1s blink infinite 0.4s;
  }

  &:nth-of-type(3) {
    transform: rotate(225deg);
    animation: 1s blink infinite 0.6s;
  }

  @keyframes blink {
    50% {
      opacity: 1;
    }
  }
`;

const LogoImage = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  left: 12px;
  top: 16px;
`;
