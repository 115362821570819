import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BuyableType } from '../../../constants/buyableType';
import {
  makeSubjectPracticeRoute,
  makeSubjectSummaryRoute,
} from '../../../constants/routes';
import { useSubjectContext } from '../../../context/SubjectContext';
import { ModalTypes, useModal } from '../../../hooks/useModal';
import { useProductAccess } from '../../../hooks/useProductAccess';
import { Section } from '../../../models/Summary/summary';
import { useGetTrainingProgressQuery } from '../../../services/trainingApi';
import { SummaryMode } from '../summary/utils/summaryUtils';

export const useSectionActions = () => {
  const { showModal } = useModal();

  const [searchParams] = useSearchParams();
  const { encodedSubjectName, sections } = useSubjectContext();
  const {
    hasChapterSectionAccess,
    hasExplanationVideoAccess,
    hasExamChallengeAccess,
  } = useProductAccess();
  const { data: trainingProgress } = useGetTrainingProgressQuery(
    encodedSubjectName ?? skipToken
  );

  const navigate = useNavigate();

  const setTraining = (section: Section) => {
    if (!encodedSubjectName) return;
    const practiceRoute = makeSubjectPracticeRoute(encodedSubjectName);
    const params =
      searchParams.size > 0
        ? searchParams
        : `?section=${section.encodedSectionName}`;
    navigate(`${practiceRoute}${params}`);
  };

  const openBuyableModal = (buyableType: BuyableType) => {
    if (!encodedSubjectName) return;
    showModal({
      type: ModalTypes.BuySectionModal,
      data: {
        encodedSubjectName,
        buyableType,
      },
    });
  };

  const openTraining = ({
    section,
    encodedChapterName,
    startNewTraining,
  }: {
    encodedChapterName: string;
    startNewTraining?: boolean;
    section?: Section;
  }) => {
    if (!section || !sections) return;

    if (!hasExamChallengeAccess(section, sections)) {
      openBuyableModal(BuyableType.Training);
      return;
    }
    if (
      !startNewTraining &&
      trainingProgress?.lastThesisId &&
      trainingProgress?.encodedSectionName === section?.encodedSectionName
    ) {
      setTraining(section);
    } else {
      showModal({
        type: ModalTypes.StartTrainingModal,
        data: {
          encodedSubjectName,
          encodedChapterName,
          encodedSectionName: section?.encodedSectionName,
        },
      });
    }
  };

  const openSummary = (section: Section) => {
    if (!section || !sections) return;

    if (!hasChapterSectionAccess(section, sections)) {
      openBuyableModal(BuyableType.Summary);
      return;
    }
    openSummaryPage(section, SummaryMode.Summary);
  };

  const openVideo = (section: Section) => {
    if (!section || !sections) return;

    if (!hasExplanationVideoAccess(section, sections)) {
      openBuyableModal(BuyableType.Videos);
      return;
    }
    openSummaryPage(section, SummaryMode.Video);
  };

  const openSummaryPage = (section: Section, mode: SummaryMode) => {
    if (!encodedSubjectName) return;
    const searchParams = new URLSearchParams({
      section: section.encodedSectionName,
      mode: mode,
    });
    const summaryRoute = makeSubjectSummaryRoute(encodedSubjectName);
    navigate(`${summaryRoute}?${searchParams}`);
  };

  return { openSummary, openVideo, openTraining };
};
