import { ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../constants/routes';
import { ProductBundle } from '../../models/ProductBundle';
import { useAppDispatch } from '../../store/hooks';
import { addToCart } from '../../store/slices/cartSlice';
import { setCoverUrlToSummary } from '../../utils/apiUtils';
import { CartItem } from '../CartItem';

type Props = {
  productBundle: ProductBundle;
  onPress?: () => void;
} & ComponentProps<'div'>;

export const BuyableItem = ({ productBundle, ...props }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onPress = () => {
    dispatch(addToCart(productBundle));
    navigate(appRoutes.Cart);
    if (props.onPress) {
      props.onPress();
    }
  };

  const oldPrice =
    productBundle.discountedPrice !== productBundle.price
      ? productBundle.price
      : undefined;

  return (
    <CartItem
      image={setCoverUrlToSummary(productBundle.cover)}
      title={productBundle.description}
      subtitle={`${productBundle.name} ${productBundle.courseLevels?.[0] || ''}`}
      price={productBundle.discountedPrice}
      oldPrice={oldPrice}
      discountPercentage={productBundle.discountPercentage}
      {...props}
      onPress={onPress}
    />
  );
};
