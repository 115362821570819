import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { usePrice } from '../../../hooks/usePrice';
import { PriceInformation } from '../../../models/PriceInformation';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { DiscountSolidLabel } from '../../common/DiscountSolidLabel';

import { PriceRow } from './PriceRow';

type Props = {
  priceInformation: PriceInformation;
};

export const YouSaveRow = ({ priceInformation }: Props) => {
  const t = useTranslations();
  const { priceNumberToString } = usePrice();

  return (
    <PriceRow>
      <DiscountedTotalPrice>{t('you_save')}</DiscountedTotalPrice>

      <DiscountContainer>
        {priceInformation.discountPercentage > 0 && (
          <DiscountSolidLabel discount={priceInformation.discountPercentage} />
        )}
        <DiscountedTotalPrice>
          {priceNumberToString(priceInformation.discountPrice)}
        </DiscountedTotalPrice>
      </DiscountContainer>
    </PriceRow>
  );
};

const DiscountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const DiscountedTotalPrice = styled.h1`
  margin: 0;
  ${TextStyles.PriceBigText}
  color: ${Colors.discountLabelBackgroundGreen};
`;
