import { useSearchParams } from 'react-router-dom';

import { allSubjectCategories } from '../../constants/constants';
import { Subject } from '../../models/Subject';
import { UserRole } from '../../models/UserRole';
import { useGetSubjectsByCourseLevelQuery } from '../../services/onboardingApi';
import { useAppDispatch } from '../../store/hooks';
import {
  addSubject,
  removeSubject,
} from '../../store/slices/userManagementSlice';
import { useUserManagement } from '../useUserManagement';

const COURSE_LEVEL_PARAM = 'level';

export const useAccountSubjects = (role: UserRole | undefined) => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const courseLevelParam = searchParams.get(COURSE_LEVEL_PARAM);

  const userManagementState = useUserManagement();
  const courseLevel =
    courseLevelParam ||
    userManagementState.teacherCourseLevels?.[0] ||
    userManagementState.courseLevel;

  const selectedSubjects = userManagementState.subjects || [];

  if (!courseLevel) {
    throw new Error('No course level found');
  }

  const { data: subjects } = useGetSubjectsByCourseLevelQuery({
    courseLevelEncoded: courseLevel,
    categories: allSubjectCategories,
  });

  const addOrRemoveSelectedSubject = (subject: Subject) => {
    if (
      userManagementState.subjects?.some(
        (s) => s.encodedName === subject.encodedName
      )
    ) {
      dispatch(removeSubject(subject._id));
    } else {
      dispatch(addSubject(subject));
    }
  };

  const getNextCourseLevel = () => {
    if (
      role !== UserRole.teacher ||
      !userManagementState.teacherCourseLevels ||
      !courseLevel
    )
      return;

    const currentCourseLevelIndex =
      userManagementState.teacherCourseLevels.indexOf(courseLevel);

    const nextCourseLevel =
      userManagementState.teacherCourseLevels[currentCourseLevelIndex + 1];

    return nextCourseLevel;
  };

  return {
    addOrRemoveSelectedSubject,
    selectedSubjects,
    getNextCourseLevel,
    subjects,
    courseLevel,
  };
};
