import { IconProps, Icon } from './Icon';

export const BadgeSilver = ({ color, ...props }: IconProps) => (
  <Icon {...props} width="71" height="39" viewBox="0 0 71 39" fill="none">
    <path
      d="M1 8.00001C1 3.85787 4.35787 0.5 8.5 0.5H62.5C66.6421 0.5 70 3.85786 70 8V22.8039C70 26.1383 67.7986 29.0724 64.5972 30.0048L37.5972 37.8684C36.2275 38.2673 34.7725 38.2673 33.4028 37.8684L6.40279 30.0048C3.20139 29.0724 1 26.1384 1 22.8039V8.00001Z"
      fill="white"
    />
    <path
      d="M1 8.00001C1 3.85787 4.35787 0.5 8.5 0.5H62.5C66.6421 0.5 70 3.85786 70 8V22.8039C70 26.1383 67.7986 29.0724 64.5972 30.0048L37.5972 37.8684C36.2275 38.2673 34.7725 38.2673 33.4028 37.8684L6.40279 30.0048C3.20139 29.0724 1 26.1384 1 22.8039V8.00001Z"
      fill="#EDBA5A"
      fillOpacity="0.2"
    />
    <path
      d="M1 8.00001C1 3.85787 4.35787 0.5 8.5 0.5H62.5C66.6421 0.5 70 3.85786 70 8V22.8039C70 26.1383 67.7986 29.0724 64.5972 30.0048L37.5972 37.8684C36.2275 38.2673 34.7725 38.2673 33.4028 37.8684L6.40279 30.0048C3.20139 29.0724 1 26.1384 1 22.8039V8.00001Z"
      fill="#D9D9D9"
    />
    <path
      d="M1 8.00001C1 3.85787 4.35787 0.5 8.5 0.5H62.5C66.6421 0.5 70 3.85786 70 8V22.8039C70 26.1383 67.7986 29.0724 64.5972 30.0048L37.5972 37.8684C36.2275 38.2673 34.7725 38.2673 33.4028 37.8684L6.40279 30.0048C3.20139 29.0724 1 26.1384 1 22.8039V8.00001Z"
      fill="#EFEFEF"
    />
    <path
      d="M1 8.00001C1 3.85787 4.35787 0.5 8.5 0.5H62.5C66.6421 0.5 70 3.85786 70 8V22.8039C70 26.1383 67.7986 29.0724 64.5972 30.0048L37.5972 37.8684C36.2275 38.2673 34.7725 38.2673 33.4028 37.8684L6.40279 30.0048C3.20139 29.0724 1 26.1384 1 22.8039V8.00001Z"
      fill="white"
      fillOpacity="0.16"
    />
    <path
      d="M1 8.00001C1 3.85787 4.35787 0.5 8.5 0.5H62.5C66.6421 0.5 70 3.85786 70 8V22.8039C70 26.1383 67.7986 29.0724 64.5972 30.0048L37.5972 37.8684C36.2275 38.2673 34.7725 38.2673 33.4028 37.8684L6.40279 30.0048C3.20139 29.0724 1 26.1384 1 22.8039V8.00001Z"
      stroke="#D7D7D7"
    />
  </Icon>
);
