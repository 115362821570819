import { DeviceInfo } from '../components/login/hooks/useDevice';
import { FormData as EditProfileFormData } from '../components/modals/edit-profile/EditProfileSchema';
import {
  ApiTagTypes,
  LOGIN_ENDPOINT,
  VERIFY_EMAIL_ENDPOINT,
} from '../constants/api';
import { BuyableType } from '../constants/buyableType';
import { UserBadge } from '../models/Badge';
import { LeaderBoard } from '../models/LeaderBoard';
import { ProductBundle } from '../models/ProductBundle';
import { Subject } from '../models/Subject';
import { User, Token } from '../models/User';

import { baseApi } from './baseApi';

type LoginBody = {
  admin: boolean;
  email: string;
  password: string;
  device: {
    identifier: string;
    description: string;
    platform: string;
  };
};

export type Error = {
  status: number;
  data: {
    message: string;
    statusCode: number;
  };
};

const USER_PRODUCTS_CACHE_INVALIDATION_IN_SECONDS = 300;

export const userApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [
      ApiTagTypes.Login,
      ApiTagTypes.Exists,
      ApiTagTypes.ProductBundlesRecommendation,
      ApiTagTypes.ProductBundles,
      ApiTagTypes.User,
      ApiTagTypes.Devices,
      ApiTagTypes.UserProducts,
      ApiTagTypes.UserSubjects,
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      [LOGIN_ENDPOINT]: build.mutation<User, LoginBody>({
        query: (loginBody) => ({
          url: `/user/login`,
          method: 'POST',
          body: loginBody,
        }),
        invalidatesTags: [ApiTagTypes.User],
      }),
      getUserExists: build.query<boolean, string>({
        query: (email) => ({
          url: `/user/${email}/exists`,
        }),
        providesTags: [ApiTagTypes.Exists],
      }),
      postForgotPasswordWithEmail: build.mutation<undefined, { email: string }>(
        {
          query: (email) => ({
            url: `/user/password/forgot`,
            method: 'POST',
            body: email,
          }),
        }
      ),
      putResetPassword: build.mutation<
        undefined,
        { pwResetKey: string; password: string }
      >({
        query: (body) => ({
          url: `/user/password/reset`,
          method: 'PUT',
          body: body,
        }),
      }),
      putUnblockAccount: build.mutation<undefined, { key: string }>({
        query: (body) => ({
          url: `/user/account/unblock`,
          method: 'PUT',
          body: body,
        }),
      }),
      getBuyAbleProductBundlesForUser: build.query<
        ProductBundle[],
        {
          productBundleIds?: string[];
          subjectId?: string;
          limit?: number;
          productTypes?: BuyableType[];
        }
      >({
        query: ({ productBundleIds, subjectId, limit, productTypes }) => ({
          url: `/user/productbundles/buyable`,
          params: {
            productBundleIds,
            subjectId,
            limit,
            productTypes,
          },
        }),
        providesTags: [ApiTagTypes.ProductBundlesRecommendation],
      }),
      getUser: build.query<User, void>({
        query: () => '/user',
        providesTags: [ApiTagTypes.User],
      }),
      deleteUser: build.mutation<User, void>({
        query: () => ({ url: '/user', method: 'DELETE' }),
      }),
      postSocialLogin: build.mutation<
        User,
        {
          provider: string;
          body: { accessToken?: string; code?: string; device: DeviceInfo };
        }
      >({
        query: ({ provider, body }) => ({
          url: `/user/login/${provider}`,
          method: 'POST',
          body: body,
        }),
        invalidatesTags: [ApiTagTypes.User],
      }),
      postLogout: build.mutation<undefined, void>({
        query: () => ({
          url: `/user/logout`,
          method: 'POST',
        }),
      }),
      getUserDevices: build.query<DeviceInfo[], void>({
        query: () => '/user/devices',
        providesTags: [ApiTagTypes.Devices],
      }),
      deleteDevice: build.mutation<undefined, { identifier: string }>({
        query: ({ identifier }) => ({
          url: `/user/device`,
          method: 'DELETE',
          body: { identifier },
          invalidatesTags: [ApiTagTypes.Devices],
        }),
        invalidatesTags: [ApiTagTypes.Devices],
      }),
      putEditProfile: build.mutation<User, EditProfileFormData>({
        query: (body) => ({
          url: `/user/edit/profile`,
          method: 'PUT',
          body: body,
          invalidatesTags: [ApiTagTypes.User],
        }),
      }),
      putEditSubjects: build.mutation<User, { subjects: Subject[] }>({
        query: (body) => ({
          url: `/user/edit/subjects`,
          method: 'PUT',
          body: body,
        }),
        invalidatesTags: [ApiTagTypes.User, ApiTagTypes.UserSubjects],
      }),
      putEditCourseLevel: build.mutation<User, { courseLevel: string }>({
        query: (body) => ({
          url: `/user/edit/courselevel`,
          method: 'PUT',
          body: body,
          invalidatesTags: [ApiTagTypes.User],
        }),
        invalidatesTags: [ApiTagTypes.User],
      }),
      postAddClass: build.mutation<
        undefined,
        { studentName: string; studentCode: string }
      >({
        query: (body) => ({
          url: `/classes/join`,
          method: 'POST',
          body: body,
        }),
        invalidatesTags: [ApiTagTypes.User],
      }),
      getUserProducts: build.query<ProductBundle[], void>({
        query: () => '/user/products',
        providesTags: [ApiTagTypes.UserProducts],
        keepUnusedDataFor: USER_PRODUCTS_CACHE_INVALIDATION_IN_SECONDS,
      }),
      [VERIFY_EMAIL_ENDPOINT]: build.mutation<
        Token,
        { code: string; email: string }
      >({
        query: (body) => ({
          url: `/user/email/verify`,
          method: 'PUT',
          body: body,
        }),
        invalidatesTags: [ApiTagTypes.User],
      }),
      getRequiredGrade: build.mutation<
        User,
        { subjectId: string; grade: string }
      >({
        query: ({ subjectId, grade }) => ({
          url: `/user/edit/${subjectId}/grade`,
          method: 'PUT',
          body: { grade },
        }),
        invalidatesTags: [ApiTagTypes.User],
      }),
      getBadges: build.query<UserBadge[], void>({
        query: () => '/user/badges',
      }),
      getLeaderBoard: build.query<LeaderBoard, void>({
        query: () => '/v2/user/leaderboard',
      }),
      getSupportedFeatures: build.query<string[], void>({
        query: () => '/auth/features',
      }),
    }),
  });

export const {
  useLoginMutation,
  useGetUserExistsQuery,
  useLazyGetUserExistsQuery,
  usePostForgotPasswordWithEmailMutation,
  usePutResetPasswordMutation,
  usePutUnblockAccountMutation,
  useGetBuyAbleProductBundlesForUserQuery,
  useGetUserQuery,
  usePostSocialLoginMutation,
  usePostLogoutMutation,
  useGetUserDevicesQuery,
  useDeleteDeviceMutation,
  usePutEditProfileMutation,
  usePostAddClassMutation,
  useGetUserProductsQuery,
  useLazyGetUserProductsQuery,
  useVerifyEmailMutation,
  usePutEditSubjectsMutation,
  usePutEditCourseLevelMutation,
  useGetRequiredGradeMutation,
  useGetBadgesQuery,
  useGetLeaderBoardQuery,
  useGetSupportedFeaturesQuery,
  useDeleteUserMutation,
} = userApi;
