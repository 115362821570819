import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { BuyableType } from '../../../constants/buyableType';
import { HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT } from '../../../constants/Sizes';
import {
  useGetSubjectByEncodedNameQuery,
  useGetSubjectExercisesByIdQuery,
} from '../../../services/subjectsApi';
import { CardBase } from '../../common/CardBase';
import { useRecommendedBundles } from '../summary/hooks/useRecommendedBundles';

import { ExerciseCardHeader } from './ExerciseCardHeader';
import { useLoadBoxPDFResources } from './hooks/useLoadBoxPDFResources';

export const SubjectExercises = () => {
  const t = useTranslations();
  const { encodedSubjectName } = useParams();
  const previewContainerRef = useRef<HTMLDivElement>(null);

  const { data: subjectData } = useGetSubjectByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );
  const subjectId = subjectData?._id;
  const { data: exerciseData } = useGetSubjectExercisesByIdQuery(
    subjectId ?? skipToken
  );

  const recommendations = useRecommendedBundles(
    subjectId,
    [BuyableType.Exercises],
    1
  );
  const buyableProduct = recommendations?.[0];

  useLoadBoxPDFResources(exerciseData, previewContainerRef);

  return (
    <CardBase
      fullWidth
      fullWidthBody
      header={
        buyableProduct && <ExerciseCardHeader buyableProduct={buyableProduct} />
      }
    >
      {exerciseData ? (
        <BoxDocPreview
          ref={previewContainerRef}
          className="preview-container"
        />
      ) : (
        <NoExerciseBookContainer>
          {t('there_is_no_exercise_book')}
        </NoExerciseBookContainer>
      )}
    </CardBase>
  );
};

const BoxDocPreview = styled.div`
  height: calc(100vh - ${HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT});
  width: 100%;
`;

const NoExerciseBookContainer = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
