import { ProductBundle } from './ProductBundle';

export enum DealType {
  GOLD = 'GOLD',
  SILVER = 'SILVER',
  BRONZE = 'BRONZE',
}

export type Deal = {
  productBundles: ProductBundle[];
  imageUrl: string;
  totalPrice: number;
  discountPercentage: number;
  discountedPrice: number;
  type: DealType;
};
