import { createContext, MutableRefObject, useEffect, useState } from 'react';

import { config } from '../config';
import { useLogout } from '../hooks/useLogout';
import { ModalTypes, useModal } from '../hooks/useModal';
import { UserBadge } from '../models/Badge';
import { useAppSelector } from '../store/hooks';

import { SocketEvent, SocketInterface } from './socket.types';
import SocketFactory from './SocketFactory';

type SocketContextValue = {
  loginSocket: MutableRefObject<SocketInterface | undefined> | undefined;
  badgeSocket: MutableRefObject<SocketInterface | undefined> | undefined;
  trackingSocket: MutableRefObject<SocketInterface | undefined> | undefined;
};

export const SocketContext = createContext<SocketContextValue>({
  loginSocket: undefined,
  badgeSocket: undefined,
  trackingSocket: undefined,
});

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [loginSocket, setLoginSocket] = useState<SocketInterface>();
  const [badgeSocket, setBadgeSocket] = useState<SocketInterface>();
  const [trackingSocket, setTrackingSocket] = useState<SocketInterface>();

  const userState = useAppSelector((state) => state.user);
  const { logout } = useLogout();
  const { showModal } = useModal();

  // Login socket
  useEffect(() => {
    if (!userState.token?.userId) return;

    const socketConnection = SocketFactory.create(config.API_BASE_URL, {
      secure: true,
      rejectUnauthorized: false,
    });

    socketConnection.socket.on('connect_error', (error) => {
      console.error('Login socket connection error:', error);
    });

    socketConnection.socket.emit(
      SocketEvent.loginRegister,
      userState.token.userId
    );

    socketConnection.socket.on(SocketEvent.loginDevice, () => {
      showModal({
        type: ModalTypes.AutomaticLogOutModal,
      });
      logout();
    });

    setLoginSocket(socketConnection);

    return () => {
      socketConnection.socket.off(SocketEvent.loginDevice);
      socketConnection.socket.disconnect();
    };
  }, [userState.token?.userId]);

  // Badge socket
  useEffect(() => {
    if (!userState.token?.token) return;

    const socketConnection = SocketFactory.create(config.SOCKET_BADGES, {
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: `Bearer ${userState.token.token}`,
          },
        },
      },
    });

    socketConnection.socket.on('connect_error', (error) => {
      console.error('Badge socket connection error:', error);
    });

    socketConnection.socket.on(
      SocketEvent.badgeCompleted,
      (userBadge: UserBadge) => {
        showModal({
          type: ModalTypes.BadgeUnlockedModal,
          data: {
            badge: userBadge,
          },
        });
      }
    );

    setBadgeSocket(socketConnection);

    return () => {
      socketConnection.socket.off(SocketEvent.badgeCompleted);
      socketConnection.socket.disconnect();
    };
  }, [userState.token?.token]);

  // Tracking socket
  useEffect(() => {
    if (!userState.token?.token) return;

    const socketConnection = SocketFactory.create(config.SOCKET_TRACKING, {
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: `Bearer ${userState.token.token}`,
          },
        },
      },
    });

    socketConnection.socket.on('connect_error', (error) => {
      console.error('Tracking socket connection error:', error);
    });

    setTrackingSocket(socketConnection);

    return () => {
      socketConnection.socket.disconnect();
    };
  }, [userState.token?.token]);

  return (
    <SocketContext.Provider
      value={{
        loginSocket: loginSocket ? { current: loginSocket } : undefined,
        badgeSocket: badgeSocket ? { current: badgeSocket } : undefined,
        trackingSocket: trackingSocket
          ? { current: trackingSocket }
          : undefined,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
