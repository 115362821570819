import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

type Props = {
  discount: number;
};

export const DiscountSolidLabel = ({ discount }: Props) => {
  const t = useTranslations();

  return (
    <Container>
      <Text>
        {discount}% {t('discount')}
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.discountLabelBackgroundGreen};
  padding: 2px 6px;
  border-radius: 4px;
`;

const Text = styled.div`
  color: ${Colors.primaryButtonContentColor};
  ${TextStyles.DiscountLabelText}
`;
