import styled from '@emotion/styled';
import { ReactElement } from 'react';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { CheckIcon } from '../Icons/CheckIcon';

type Props = {
  text: string;
  subText?: string;
  icon?: ReactElement;
  isActive: boolean;
  onClick: () => void;
};

export const SelectableItem = ({
  text,
  subText,
  icon,
  isActive,
  onClick,
}: Props) => (
  <Container isActive={isActive} onClick={onClick}>
    {icon}
    <TextContainer>
      <Text>{text}</Text>
      {subText && <SubText>{subText}</SubText>}
    </TextContainer>

    {isActive && <CheckIcon color={Colors.primaryButtonFill} />}
  </Container>
);

const Container = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  border: 1px solid
    ${({ isActive }) =>
      isActive ? Colors.primaryButtonFill : Colors.inputBorderColor};
  border-radius: 8px;
  ${({ isActive }) =>
    isActive && `background-color: ${Colors.listItemSelected};`}
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
`;

const Text = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;
const TextContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;
const SubText = styled.div`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;
