import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Messages } from '../global.d';
import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

import { DiscountSolidLabel } from './common/DiscountSolidLabel';

type Props = {
  titleKey: keyof Messages;
  discountPercentage?: number;
  amountOfProducts?: number;
};

export const RecommendationCardHeader = ({
  titleKey,
  discountPercentage,
  amountOfProducts,
}: Props) => {
  const t = useTranslations();

  return (
    <Container>
      <Title>{t(titleKey)}</Title>
      {!!discountPercentage && !!amountOfProducts && (
        <>
          <DiscountText>
            {t('x_amount_of_products_for_discount', {
              count: amountOfProducts,
            })}
          </DiscountText>
          <DiscountSolidLabel discount={discountPercentage} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
`;

const DiscountText = styled.p`
  flex: 1;
  margin: 0;
  text-align: right;
  white-space: nowrap;
  color: ${Colors.discountLabelBackgroundGreen};
  ${TextStyles.DiscountLabelText}
`;

const Title = styled.h1`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
