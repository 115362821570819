import { ApiTagTypes } from '../constants/api';
import { PaymentResponse, Payment, PaymentOption } from '../models/payments';
import { PriceInformation } from '../models/PriceInformation';

import { baseApi } from './baseApi';

export const cartApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.ProductBundles, ApiTagTypes.User],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createPayment: build.mutation<PaymentResponse, Payment>({
        query: (deliveryAddress) => ({
          url: `/payments`,
          method: 'POST',
          body: deliveryAddress,
          invalidatesTags: [ApiTagTypes.UserProducts],
        }),
      }),
      getPaymentOptions: build.query<PaymentOption[], void>({
        query: () => ({
          url: `/v2/payment/options`,
        }),
      }),
      getPriceInformation: build.query<
        PriceInformation,
        { productBundleIds: string[]; discountCode: string | undefined }
      >({
        query: ({ productBundleIds, discountCode }) => ({
          url: `/product-bundles/price-information`,
          params: {
            productBundleIds: productBundleIds.join(','),
            discountCode,
          },
        }),
      }),
      validateDiscountCode: build.query<
        void,
        { code: string; productBundleIds: string[] }
      >({
        query: ({ code, productBundleIds }) => ({
          url: `/discount/validate`,
          params: { code, productBundleIds },
        }),
      }),
    }),
  });

export const {
  useCreatePaymentMutation,
  useGetPaymentOptionsQuery,
  useGetPriceInformationQuery,
  useValidateDiscountCodeQuery,
  useLazyValidateDiscountCodeQuery,
} = cartApi;
