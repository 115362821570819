import { IconProps, Icon } from './Icon';

export const MultiDeviceIcon = ({ color, ...props }: IconProps) => (
  <Icon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
    color={color}
  >
    <path
      d="M6.33334 5.5H16.3333C16.6083 5.5 16.8333 5.725 16.8333 6V7H18.3333V6C18.3333 4.89688 17.4365 4 16.3333 4H6.33334C5.23022 4 4.33334 4.89688 4.33334 6V14H2.83334C2.55834 14 2.33334 14.225 2.33334 14.5C2.33334 15.3281 3.00522 16 3.83334 16H13.3333V14H5.83334V6C5.83334 5.725 6.05834 5.5 6.33334 5.5ZM14.3333 18.5C14.3333 19.3281 15.0052 20 15.8333 20H20.8333C21.6615 20 22.3333 19.3281 22.3333 18.5V9.5C22.3333 8.67188 21.6615 8 20.8333 8H15.8333C15.0052 8 14.3333 8.67188 14.3333 9.5V18.5ZM20.8333 18.5H15.8333V9.5H20.8333V18.5Z"
      fill={color}
    />
  </Icon>
);
