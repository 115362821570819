import styled from '@emotion/styled';

import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

import { CardBase, RenderStyle } from './common/CardBase';

type ActionSubjectCardProps = {
  subjectColor?: string;
  onPress: () => void;
  title: string;
  subTitle?: string;
};

export const ActionSubjectCard = ({
  subjectColor,
  onPress,
  title,
  subTitle,
  ...props
}: ActionSubjectCardProps) => {
  return (
    <Button
      onClick={onPress}
      bgColor={subjectColor}
      renderStyle={RenderStyle.desktop}
      {...props}
    >
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </Button>
  );
};

const Button = styled(CardBase)<{ bgColor?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.bgColor};
  border: none;
  cursor: pointer;
  gap: 4px;
  margin-top: 0;
`;

const Title = styled.span`
  color: ${Colors.primaryButtonContentColor};
  ${TextStyles.ListItemTitle}
  display: block;
`;
const SubTitle = styled.span`
  color: ${Colors.primaryButtonContentColor};
  ${TextStyles.ListItemSubtitle}
  display: block;
`;
