import { ApiTagTypes } from '../constants/api';
import { Deal } from '../models/Deal';
import { ProductDiscountRates } from '../models/ProductDiscountRates';

import { baseApi } from './baseApi';

export const productApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.ProductBundles],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getDealsByUserSubjects: build.query<Deal[], string[]>({
        query: (subjectIds) => ({
          url: `/products/deals`,
          params: { subjectIds },
        }),
      }),
      getProductDiscountRates: build.query<ProductDiscountRates, number>({
        query: (amountOfProducts) => ({
          url: `/products/discount-rates`,
          params: { amountOfProducts },
        }),
      }),
    }),
  });

export const {
  useGetDealsByUserSubjectsQuery,
  useLazyGetDealsByUserSubjectsQuery,
  useGetProductDiscountRatesQuery,
} = productApi;
