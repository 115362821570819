import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { LocalStorageKey } from '../../constants/localStorage';
import { DeliveryAddress, UserShipment } from '../../models/payments';
import { PriceInformation } from '../../models/PriceInformation';
import { useGetUserQuery } from '../../services/userApi';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { PrimaryButton } from '../common/Button';

import { CartPaymentOptions } from './CartPaymentOptions';
import { ShipmentForm } from './ShipmentForm';
import { ShippingFormSchema, FormData } from './ShippingFormSchema';
import { usePayment } from './usePayment';

const DEFAULT_SHIPMENT_COUNTRY = 'Nederland';

type Props = {
  priceInformation: PriceInformation;
};

export const ShippingAndPayment = ({ priceInformation }: Props) => {
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<
    string | undefined
  >(undefined);
  const { data: user } = useGetUserQuery();
  const t = useTranslations();
  const { submitPayment } = usePayment();

  const getStoredInfo = () => {
    if (user?._id) {
      return JSON.parse(
        localStorage.getItem(
          `${user._id}:${LocalStorageKey.userShippingDetails}`
        ) || '{}'
      ) as DeliveryAddress & UserShipment;
    }
  };

  const storedInfo = getStoredInfo();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(ShippingFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    values: storedInfo && {
      ...storedInfo,
      zipCode: storedInfo.postalCode,
      houseNumber: storedInfo.streetNumber,
    },
  });

  const onSubmit = async (data: FormData) => {
    if (!user) return;

    const deliveryAddress: DeliveryAddress = {
      country: DEFAULT_SHIPMENT_COUNTRY,
      street: data.street,
      postalCode: data.zipCode?.replace(/\s/g, '')?.toUpperCase(),
      city: data.city,
      streetNumber: data.houseNumber,
      addition: data.addition,
    };

    const userShipment: UserShipment = {
      id: user._id,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
    };

    localStorage.setItem(
      `${user._id}:${LocalStorageKey.userShippingDetails}`,
      JSON.stringify({
        ...deliveryAddress,
        ...userShipment,
      })
    );

    await submitPayment(
      deliveryAddress,
      userShipment,
      priceInformation,
      selectedPaymentOptionId
    );
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Title>{t('delivery_address')}</Title>
      <ShipmentForm register={register} errors={errors} setFocus={setFocus} />

      <div>
        <Title>{t('payment_method')}</Title>
        <CartPaymentOptions
          setPaymentOption={setSelectedPaymentOptionId}
          selectedPaymentOption={selectedPaymentOptionId}
        />
      </div>
      <PrimaryButton
        buttonSize="md"
        textSize="sm"
        type="submit"
        fullWidth={true}
        disabled={priceInformation.productBundles.length === 0}
      >
        {t('finish_order')}
      </PrimaryButton>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
