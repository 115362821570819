import { BuyableType } from '../../../../constants/buyableType';
import { productTypePrefix } from '../../../../models/training/buyable';

export enum SummaryMode {
  Summary = 'summary',
  Video = 'video',
  Both = 'both',
}

export const getProductTypePrefixFromBuyableType = (
  buyableType: BuyableType
) => {
  switch (buyableType) {
    case BuyableType.Training:
      return productTypePrefix.practiceModule;
    case BuyableType.Summary:
      return productTypePrefix.summary;
    case BuyableType.Videos:
      return productTypePrefix.videos;
  }
};

export const getBuyableFilterBySummaryMode = (summaryMode: SummaryMode) => {
  switch (summaryMode) {
    case SummaryMode.Summary:
      return [BuyableType.Summary];
    case SummaryMode.Video:
      return [BuyableType.Videos];
    default:
      return [BuyableType.Summary, BuyableType.Videos];
  }
};
