import { Platform } from '../constants/platforms';
import { Version } from '../models/Version';

import { baseApi } from './baseApi';

export const versionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getVersion: build.query<Version, { platform: Platform; version: string }>({
      query: (params) => ({
        url: '/version/status',
        params: {
          platform: params.platform.toUpperCase(),
          version: params.version,
        },
      }),
    }),
  }),
});

export const { useGetVersionQuery, useLazyGetVersionQuery } = versionApi;
