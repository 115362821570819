import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useTranslations } from 'use-intl';

import { ProductBundle } from '../../models/ProductBundle';
import { useLazyGetUserProductsQuery } from '../../services/userApi';
import { useAppDispatch } from '../../store/hooks';
import { deleteFromCart } from '../../store/slices/cartSlice';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { setCoverUrlToSummary } from '../../utils/apiUtils';
import { CardType, CartItem } from '../CartItem';
import { CardBase } from '../common/CardBase';
import { Loader } from '../common/Loader';

type Props = {
  productBundles: ProductBundle[];
  isLoading: boolean;
};

export const CartItemCard = ({ productBundles, isLoading }: Props) => {
  const t = useTranslations();
  const [getUserProducts] = useLazyGetUserProductsQuery();
  const dispatch = useAppDispatch();

  const onRemoveItem = (productBundle: ProductBundle) => {
    dispatch(deleteFromCart(productBundle));
  };

  const alreadyBoughtCheck = async () => {
    try {
      const userProducts = await getUserProducts().unwrap();
      const purchasedProductIds = new Set(
        userProducts.map((product) => product._id)
      );

      const alreadyBoughtItems = productBundles.filter(
        (cartItem) =>
          cartItem.productIds?.some((productId) =>
            purchasedProductIds.has(productId)
          ) ||
          cartItem.bundleProducts?.some((product) =>
            purchasedProductIds.has(product._id)
          )
      );

      alreadyBoughtItems?.forEach((product) => {
        dispatch(deleteFromCart(product));
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    alreadyBoughtCheck();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        alreadyBoughtCheck();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <CardBase header={<Title>{t('your_order')}</Title>} fullWidth={true}>
      {productBundles.map((productBundle: ProductBundle) => (
        <CartItem
          key={productBundle._id}
          image={setCoverUrlToSummary(productBundle.cover)}
          title={productBundle.description}
          subtitle={`${productBundle.name} ${productBundle.courseLevels?.[0] || ''}`}
          price={productBundle.discountedPrice}
          oldPrice={
            productBundle.discountedPrice !== productBundle.price
              ? productBundle.price
              : undefined
          }
          onPress={() => onRemoveItem(productBundle)}
          cardType={CardType.removeFromCart}
        />
      ))}
      {isLoading && <Loader />}
      {!isLoading && productBundles.length === 0 && (
        <EmptyCartText>{t('empty_cart')}</EmptyCartText>
      )}
    </CardBase>
  );
};

const Title = styled.h1`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;

const EmptyCartText = styled.p`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemBody}
`;
