import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldError, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Messages } from '../../../global.d';
import { useEncodedPassword } from '../../../hooks/useEncodedPassword';
import { useError } from '../../../hooks/useError';
import { usePutResetPasswordMutation, Error } from '../../../services/userApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { FooterAlignment } from '../../common/CardBase';
import { InputFieldComponent } from '../../common/Input';
import { LoginCardBase } from '../LoginCardBase';

import { ResetFooter } from './ResetFooter';
import { ResetFormSchema, FormData, FormFields } from './ResetFormSchema';

export const ResetCard = () => {
  const t = useTranslations();
  const { key } = useParams();
  const [reset] = usePutResetPasswordMutation();
  const { showErrorModal } = useError();
  const encodePassword = useEncodedPassword();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(ResetFormSchema),
  });

  const { ref: passwordRef, ...passwordRegister } = register(
    FormFields.password
  );
  const { ref: repeatPasswordRef, ...repeatPasswordRegister } = register(
    FormFields.repeatPassword
  );

  const navigate = useNavigate();

  const onSubmit = (data: FormData) => handleChangePassword(data.password);

  const handleChangePassword = async (password: string) => {
    if (!key) {
      navigate('/login');
      return;
    }

    try {
      await reset({
        pwResetKey: key,
        password: encodePassword(password),
      }).unwrap();

      navigate('/login');
    } catch (err) {
      const error = err as Error;
      switch (error.status) {
        case 400:
          setErrorMessage('no_valid_password');
          break;
        case 401:
          setErrorMessage('key_not_valid');
          break;
        case 404:
          setErrorMessage('key_not_valid');
          break;
        default:
          showErrorModal(error);
          break;
      }
    }
  };

  const setErrorMessage = (messageKey: keyof Messages) => {
    setError(FormFields.password, {
      type: 'manual',
      message: t(messageKey),
    });
  };

  const getErrorText = (error?: FieldError) =>
    error?.message !== undefined ? t(error.message as keyof Messages) : '';

  return (
    <LoginCardBase
      onSubmit={handleSubmit(onSubmit)}
      header={<Title>{t('reset_password')}</Title>}
      footerAlignment={FooterAlignment.right}
      footerButtons={<ResetFooter isChangeButtonEnabled={true} />}
    >
      <InputFieldComponent
        label={t('password')}
        type="password"
        hasError={errors.password !== undefined}
        errorText={getErrorText(errors.password)}
        placeholder={t('placeholder_password')}
        {...passwordRegister}
        inputRef={passwordRef}
      />
      <InputFieldComponent
        label={t('repeat_password')}
        type="password"
        hasError={errors.repeatPassword !== undefined}
        errorText={getErrorText(errors.repeatPassword)}
        placeholder={t('placeholder_password')}
        {...repeatPasswordRegister}
        inputRef={repeatPasswordRef}
      />
    </LoginCardBase>
  );
};
const Title = styled.h1`
  text-align: center;
  width: 100%;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText};
`;
