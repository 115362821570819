import { ApiTagTypes } from '../constants/api';
import { Category, Definition } from '../models/Definition';
import { Exercise } from '../models/Exercise';
import { NextUnreadSection } from '../models/NextUnreadSection';
import { OldExam } from '../models/OldExams';
import { Subject } from '../models/Subject';
import { Sections, Summary } from '../models/Summary/summary';
import { Tip } from '../models/Tip';
import { Video } from '../models/Video';

import { baseApi } from './baseApi';

export const subjectsApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [
      ApiTagTypes.Subjects,
      ApiTagTypes.Definition,
      ApiTagTypes.Tips,
      ApiTagTypes.Exercises,
      ApiTagTypes.Subjects,
      ApiTagTypes.Tips,
      ApiTagTypes.Videos,
      ApiTagTypes.Sections,
      ApiTagTypes.Summary,
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getSubjectByEncodedName: build.query<Subject, string>({
        query: (encodedSubjectName) => ({
          url: `/subjects/${encodedSubjectName}`,
        }),
        providesTags: [ApiTagTypes.Subjects],
      }),
      getSummaryByEncodedName: build.query<Summary, string>({
        query: (encodedSubjectName) => ({
          url: `/summaries/topics/${encodedSubjectName}`,
        }),
        providesTags: [ApiTagTypes.Summary],
      }),
      getSummarySections: build.query<Sections, string>({
        query: (encodedSubjectName) => ({
          url: `/summaries/topics/${encodedSubjectName}/sections`,
        }),
        providesTags: [ApiTagTypes.Sections],
      }),
      getSubjectDefinitionsByEncodedName: build.query<Category[], string>({
        query: (encodedSubjectName) => ({
          url: `/subject/${encodedSubjectName}/definitions`,
        }),
        transformResponse: (response: Definition[]) => {
          return response[0].categories;
        },
        providesTags: [ApiTagTypes.Definition],
      }),
      getSubjectTipsByEncodedName: build.query<Tip[], string>({
        query: (encodedSubjectName) => ({
          url: `/subject/${encodedSubjectName}/tips`,
        }),
        providesTags: [ApiTagTypes.Tips],
      }),
      getSubjectExercisesById: build.query<Exercise, string>({
        query: (subjectId) => ({
          url: `/subject/${subjectId}/exercises`,
        }),
        providesTags: [ApiTagTypes.Exercises],
      }),
      getSubjectVideosByEncodedName: build.query<Video[], string>({
        query: (encodedSubjectName) => ({
          url: `/subject/${encodedSubjectName}/videos`,
        }),
        providesTags: [ApiTagTypes.Videos],
      }),
      getNextUnreadSections: build.query<NextUnreadSection[], void>({
        query: () => ({
          url: `/subjects/sections/next-unread`,
          providesTags: [ApiTagTypes.Sections],
        }),
      }),
      getOldExams: build.query<OldExam[], string>({
        query: (encodedSubjectName) => ({
          url: `/subject/${encodedSubjectName}/exams`,
        }),
      }),
      getScheduleTimeSlots: build.query<{ numberOfTimeslots: string }, void>({
        query: () => ({
          url: `/show-timeslots`,
        }),
      }),
      getExamTrainingVideo: build.query<
        { videoUrl: string; subjectName: string },
        string
      >({
        query: (encodedSubjectName) => ({
          url: `/subject/${encodedSubjectName}/exam-training-video`,
        }),
      }),
    }),
  });

export const {
  useGetSubjectByEncodedNameQuery,
  useGetSubjectDefinitionsByEncodedNameQuery,
  useGetSubjectTipsByEncodedNameQuery,
  useGetSubjectExercisesByIdQuery,
  useGetSubjectVideosByEncodedNameQuery,
  useGetSummarySectionsQuery,
  useGetSummaryByEncodedNameQuery,
  useGetNextUnreadSectionsQuery,
  useGetOldExamsQuery,
  useGetScheduleTimeSlotsQuery,
  useGetExamTrainingVideoQuery,
  useLazyGetExamTrainingVideoQuery,
} = subjectsApi;
