import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';

import { appRoutes } from '../../constants/routes';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { PrimaryButton, SecondaryButton } from '../common/Button';
import { CartIcon } from '../Icons/CartIcon';

export const CardButton = ({
  cartItemsAmount,
}: {
  cartItemsAmount: number;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isCartPage = location.pathname === appRoutes.Cart;

  const cartIconColor = isCartPage
    ? Colors.primaryTextInverted
    : Colors.primaryIconColor;

  const circleBackgroundColor = isCartPage
    ? Colors.primaryTextInverted
    : Colors.primary;

  const circleTextColor = isCartPage
    ? Colors.primary
    : Colors.primaryTextInverted;

  const renderButtonBody = () => {
    return (
      <ButtonContainer>
        <StyledCartIcon color={cartIconColor} />
        <Circle
          backgroundColor={circleBackgroundColor}
          textColor={circleTextColor}
        >
          {cartItemsAmount}
        </Circle>
      </ButtonContainer>
    );
  };

  return (
    <>
      {isCartPage ? (
        <CartButtonPrimary
          buttonSize="md"
          onClick={() => navigate(appRoutes.Cart)}
        >
          {renderButtonBody()}
        </CartButtonPrimary>
      ) : (
        <CartButton buttonSize="md" onClick={() => navigate(appRoutes.Cart)}>
          {renderButtonBody()}
        </CartButton>
      )}
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
const Circle = styled.div<{ backgroundColor: string; textColor: string }>`
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 16px;
  height: 16px;
  color: ${({ textColor }) => textColor};
  ${TextStyles.NotificationDotText};
`;
const StyledCartIcon = styled(CartIcon)`
  width: 16px;
  height: 16px;
`;
const CartButton = styled(SecondaryButton)`
  padding: 12px;
`;

const CartButtonPrimary = styled(PrimaryButton)`
  padding: 12px;
`;
