import { Fragment } from 'react/jsx-runtime';

import { CardBase } from '../../../../components/common/CardBase';
import { RowDivider } from '../../../common/RowDivider';
import { BuyableItem } from '../../../dashboard/BuyableItem';
import { useRecommendedBundles } from '../../summary/hooks/useRecommendedBundles';

export const UpsellCard = ({ subjectId }: { subjectId?: string }) => {
  const buyableProducts = useRecommendedBundles(subjectId);
  return buyableProducts?.length ? (
    <CardBase fullWidth={true}>
      {buyableProducts.map((buyableProduct, i) => (
        <Fragment key={buyableProduct._id}>
          <BuyableItem productBundle={buyableProduct} />
          {i < buyableProducts.length - 1 && <RowDivider />}
        </Fragment>
      ))}
    </CardBase>
  ) : (
    <></>
  );
};
