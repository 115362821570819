import { SubjectCategory } from '../models/Subject';

export const EMAIL_VERIFICATION_COOLDOWN = 10000;
export const EMAIL_VERIFICATION_CODE_LENGTH = 6;

export const allSubjectCategories = [
  SubjectCategory.Languages,
  SubjectCategory.Exact,
  SubjectCategory.Social,
  SubjectCategory.Extra,
];

export const ENCODED_PASSWORD_PREFIX = '[!encoded-password!]';

export const SUBJECT_ID_PREFIX = 'subjectIds[]=';
export const SUBJECT_ID_JOIN_PREFIX = '&subjectIds[]=';

export const MODAL_CARD_MAX_HEIGHT = '60vh';

export const FREE_SHIPPING_REQUIREMENT_PRICE = 30;
