import styled from '@emotion/styled';

type Props = {
  children: React.ReactNode;
};

export const PriceRow = ({ children }: Props) => {
  return <Row>{children}</Row>;
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;
