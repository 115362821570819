import { ENCODED_PASSWORD_PREFIX } from '../constants/constants';

export const useEncodedPassword = () => {
  const encodePassword = (password: string) => {
    const base64Password = btoa(password);
    const encodedPassword = ENCODED_PASSWORD_PREFIX + base64Password;
    return encodedPassword;
  };

  return encodePassword;
};
