import { Deal } from '../../models/Deal';
import { UserSubject } from '../../models/Subject';

export const useSubjectsByDescription = (
  deal: Deal,
  subjects: UserSubject[] | undefined
) => {
  const getSubjectsByDescription = (description: string) => {
    const subjectIds = deal.productBundles
      .filter((bundle) => bundle.description === description)
      .flatMap((bundle) => bundle.subjectIds);

    const productSubjects = subjects
      ?.filter((userSubject) => subjectIds.includes(userSubject.subject._id))
      .map((userSubject) => userSubject.subject);
    return productSubjects;
  };

  return getSubjectsByDescription;
};
