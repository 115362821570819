import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { appRoutes } from '../../../constants/routes';
import { useAmountOfProductTypesInProductBundles } from '../../../hooks/onboarding/useAmountOfProductTypesInProductBundles';
import { useSubjectsByDescription } from '../../../hooks/onboarding/useSubjectsByDescription';
import { ModalTypes, useModal } from '../../../hooks/useModal';
import { Deal, DealType } from '../../../models/Deal';
import { UserSubject } from '../../../models/Subject';
import { useAppDispatch } from '../../../store/hooks';
import { addMultipleToCart } from '../../../store/slices/cartSlice';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { PrimaryButton, SecondaryButton } from '../../common/Button';
import { RenderStyle } from '../../common/CardBase';
import { CardContainer } from '../../common/CardContainer';
import { Price } from '../../common/Price';

import { DealCardBadge } from './DealCardBadge';
import { ProductTypeRow } from './ProductTypeRow';

type Props = {
  deal: Deal;
  subjects: UserSubject[] | undefined;
};

export const DealCard = ({ deal, subjects }: Props) => {
  const t = useTranslations();
  const { showModal } = useModal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getSubjectsByDescription = useSubjectsByDescription(deal, subjects);
  const productTypeCounts = useAmountOfProductTypesInProductBundles(
    deal.productBundles
  );

  const renderDealButton = () => {
    const text = t('take_this_deal');
    if (deal.type === DealType.GOLD) {
      return (
        <PrimaryButton buttonSize="md" fullWidth onClick={handleTakeDeal}>
          {text}
        </PrimaryButton>
      );
    }

    return (
      <SecondaryButton buttonSize="md" fullWidth onClick={handleTakeDeal}>
        {text}
      </SecondaryButton>
    );
  };

  const handleProductTypeClick = (productType: string) => {
    showModal({
      type: ModalTypes.ProductTypeDetailsModal,
      data: {
        productType,
        subjects: getSubjectsByDescription(productType),
      },
    });
  };

  const handleTakeDeal = () => {
    dispatch(addMultipleToCart(deal.productBundles));
    navigate(appRoutes.Cart);
  };

  return (
    <StyledCardContainer
      renderStyle={RenderStyle.desktop}
      isGold={deal.type === DealType.GOLD}
      fullWidth
    >
      <DealCardBadge deal={deal} />
      <TextContainer>
        <Title>{t(`title_${deal.type}`)}</Title>
        <Description>{t(`description_${deal.type}`)}</Description>
      </TextContainer>
      <ImageContainer>
        <Image src={deal.imageUrl} alt={t(`title_${deal.type}`)} />
      </ImageContainer>

      <ProductTypesContainer>
        {productTypeCounts.map((productType) => (
          <ProductTypeRow
            key={productType.description}
            productType={productType}
            handleProductTypeClick={handleProductTypeClick}
          />
        ))}
      </ProductTypesContainer>

      <Footer>
        <Price
          price={deal.discountedPrice}
          oldPrice={deal.totalPrice}
          discountPercentage={deal.discountPercentage}
          shouldDiscountFloatRight
          showNow
        />

        {renderDealButton()}
      </Footer>
    </StyledCardContainer>
  );
};

const StyledCardContainer = styled(CardContainer)<{ isGold: boolean }>`
  padding-top: 16px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  min-height: 538px;
  flex: 10;
  order: 2;

  ${({ isGold }) =>
    isGold &&
    `
    order: 1;
    border: 1px solid ${Colors.goldBorder};
    box-shadow: 0px 0px 20px 0px rgba(236, 103, 27, 0.12);
  `}

  @media ${HookMediaQueries.isDesktop} {
    ${({ isGold }) =>
      isGold &&
      `
      order: 2;
      min-height: 570px;
      flex: 11;
    `}
  }
`;

const Footer = styled.div`
  border-top: 1px solid ${Colors.dividerColor};
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 20px 32px;
`;

const Title = styled.h3`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleMedium}
`;

const Description = styled.p`
  margin: 0;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.BodySmall}
  text-align: center;
`;

const ImageContainer = styled.div``;

const Image = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
`;

const ProductTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  width: 100%;
  padding: 20px 32px 20px 32px;
  box-sizing: border-box;
  flex: 1;
`;
