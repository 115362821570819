import { UserSubject } from '../models/Subject';
import { getFirstUpcomingExamDate } from '../utils/dateUtils';

type ExamInfo = {
  userSubject: UserSubject;
  date: Date;
};

export const useUpcomingExam = (subjects: UserSubject[]): ExamInfo | null => {
  const now = Date.now();

  const subjectsWithExamDate = subjects
    .map((subject) => ({
      userSubject: subject,
      date: getFirstUpcomingExamDate(subject.subject),
    }))
    .filter(
      // extra type definition because of linter errors below
      (subject): subject is ExamInfo => subject.date !== undefined
    );

  // Find the next upcoming exam by sorting and finding first future exam
  return (
    subjectsWithExamDate
      .sort((a, b) => a.date.getTime() - b.date.getTime())
      .find((subject) => subject.date.getTime() > now) ?? null
  );
};
