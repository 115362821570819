import styled from '@emotion/styled';

import { PriceInformation } from '../../../models/PriceInformation';

import { DeliveryMessageRow } from './DeliveryMessageRow';
import { DiscountCodeRow } from './DiscountCodeRow';
import { PostageRow } from './PostageRow';
import { TotalPriceRow } from './TotalPriceRow';
import { YouSaveRow } from './YouSaveRow';

type Props = {
  priceInformation: PriceInformation;
};

export const DiscountDetails = ({ priceInformation }: Props) => {
  const hasDiscountCode = priceInformation.discountCodePrice > 0;
  const hasDiscount =
    priceInformation.discountedTotalPrice !== priceInformation.totalPrice;
  const hasPhysicalProducts = priceInformation.hasPhysicalProducts;

  return (
    <Container>
      {hasPhysicalProducts && (
        <PostageRow priceInformation={priceInformation} />
      )}
      <TotalPriceRow
        hasDiscount={hasDiscount}
        priceInformation={priceInformation}
      />
      {hasDiscountCode && (
        <DiscountCodeRow priceInformation={priceInformation} />
      )}
      {hasDiscount && <YouSaveRow priceInformation={priceInformation} />}
      {hasPhysicalProducts && <DeliveryMessageRow />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
`;
