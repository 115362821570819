import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { appRoutes } from '../../../constants/routes';
import { HEADER_HEIGHT_PX } from '../../../constants/Sizes';
import { useGetUserSubjectsQuery } from '../../../services/dashboardApi';
import { useGetDealsByUserSubjectsQuery } from '../../../services/productService';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SecondaryButton } from '../../common/Button';
import { Loader } from '../../common/Loader';

import { DealCard } from './DealCard';
import { USPs } from './USPs';

export const OneTimeOfferIndex = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const { data: userSubjects, isLoading: isUserSubjectsLoading } =
    useGetUserSubjectsQuery();
  const { data: deals, isLoading: isDealsLoading } =
    useGetDealsByUserSubjectsQuery(
      userSubjects?.map((subject) => subject.subject._id) ?? skipToken
    );

  if (isUserSubjectsLoading || isDealsLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (deals?.length === 0) {
    return (
      <LoaderContainer>
        <div>{t('no_deals_found')}</div>
      </LoaderContainer>
    );
  }

  const renderTitle = () => {
    if (isDesktop) {
      return (
        <>
          <Title>{t('one_time_offers')}</Title>
          <Subtitle>{t('one_time_offers_description')}</Subtitle>
        </>
      );
    }
    return <MobileTitle>{t('one_time_offers_mobile_title')}</MobileTitle>;
  };

  const handleSkip = () => {
    navigate(appRoutes.Dashboard);
  };

  return (
    <Container>
      {renderTitle()}
      <DealsContainer>
        {deals?.map((deal) => (
          <DealCard key={deal.type} deal={deal} subjects={userSubjects} />
        ))}
      </DealsContainer>
      <USPs />
      <MobileFooter>
        <SecondaryButton buttonSize="md" onClick={handleSkip}>
          {t('skip_deals')}
        </SecondaryButton>
      </MobileFooter>
    </Container>
  );
};

const Container = styled.div`
  padding-top: calc(30px + env(safe-area-inset-top));
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background-color: ${Colors.modalBackgroundColor};

  @media ${HookMediaQueries.isDesktop} {
    background-color: ${Colors.backgroundColor};
  }
`;

const Title = styled.h1`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleBig}
  text-align: center;
`;

const Subtitle = styled.h2`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.OneTimeOfferDescription}
  text-align: center;
`;

const MobileTitle = styled.h1`
  margin: 0;
  padding: 0 20px;
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleMedium}
`;

const DealsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  flex: 1;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  @media ${HookMediaQueries.isDesktop} {
    flex-direction: row;
    padding: 18px 0px 32px 0px;
    align-items: flex-end;
  }
`;

const MobileFooter = styled.div`
  border-top: 1px solid ${Colors.dividerColor};
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  position: sticky;
  bottom: 0;
  background-color: ${Colors.modalBackgroundColor};
  padding-bottom: calc(env(safe-area-inset-bottom) + 20px);

  @media ${HookMediaQueries.isDesktop} {
    display: none;
  }
`;

const LoaderContainer = styled.div`
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${HookMediaQueries.isDesktop} {
    height: calc(100vh - ${HEADER_HEIGHT_PX}px - 80px);
  }
`;
