import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Section, Video } from '../../../models/Summary/summary';
import {
  useGetSummarySectionsQuery,
  useGetSubjectByEncodedNameQuery,
} from '../../../services/subjectsApi';
import { useGetTrainingProgressQuery } from '../../../services/trainingApi';
import { flattenArray } from '../../../utils/mathUtils';

export const useSubjectHome = ({
  encodedSubjectName,
}: {
  encodedSubjectName?: string;
}) => {
  const [firstUntrainedPassedSection, setFirstUntrainedPassedSection] =
    useState<Section | undefined>(undefined);
  const [nextVideo, setNextVideo] = useState<Video | undefined>(undefined);
  const [nextSectionWithVideo, setNextSectionWithVideo] = useState<
    Section | undefined
  >(undefined);
  const navigate = useNavigate();
  const { data: sections } = useGetSummarySectionsQuery(
    encodedSubjectName ?? skipToken
  );
  const { data: subject } = useGetSubjectByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );
  const { data: trainingProgress } = useGetTrainingProgressQuery(
    encodedSubjectName ?? skipToken
  );
  const allSections = sections?.chapters.map((chapter) => chapter.sections);
  const flattenedSections = flattenArray(allSections ?? []);
  const firstSection: Section | undefined = flattenedSections[0];

  const getFirstPracticeSectionAvailable = () => {
    return flattenedSections?.find((section) => section?.hasPracticeModule);
  };

  const openFreeTraining = () => {
    navigate(`/subjects/${encodedSubjectName}/training-video`);
  };

  const getFirstUntrainedPassedSection = useCallback(() => {
    if (trainingProgress && trainingProgress.lastThesisId) {
      const foundSection = flattenedSections.find(
        (section: Section) =>
          section.encodedSectionName === trainingProgress?.encodedSectionName &&
          section?.hasPracticeModule
      );
      setFirstUntrainedPassedSection(foundSection);
    } else {
      const nextTrainedSectionIndex = flattenedSections.findIndex(
        (section) =>
          (!section.sectionRating || section.sectionRating < 5.5) &&
          section?.hasPracticeModule
      );
      setFirstUntrainedPassedSection(
        flattenedSections?.[nextTrainedSectionIndex]
      );
    }
  }, [trainingProgress, flattenedSections]);

  const getSectionByEncodedSectionName = (encodedSectionName: string) => {
    return flattenedSections.find(
      (section) => section.encodedSectionName === encodedSectionName
    );
  };

  const getNextVideo = useCallback(() => {
    if (!sections?.videosActive) {
      setNextVideo(undefined);
      return;
    }

    let foundVideo = null;
    let foundSection = null;

    sections.chapters.some((chapter) => {
      return chapter.sections.some((section) => {
        return section.topics.some((topic) => {
          if (topic.video) {
            foundVideo = topic.video;
            foundSection = section;
            return true;
          }
          return false;
        });
      });
    });

    if (foundVideo && foundSection) {
      setNextVideo(foundVideo);
      setNextSectionWithVideo(foundSection);
    }
  }, [sections]);

  useEffect(() => {
    getFirstUntrainedPassedSection();
    getNextVideo();
  }, [getFirstUntrainedPassedSection, getNextVideo]);

  return {
    getFirstPracticeSectionAvailable,
    firstUntrainedPassedSection,
    areExamTrainingVideosEnabled: subject?.examTrainingVideoActive ?? false,
    userHasAccessToExamTrainingVideo:
      subject?.userUnlockedExamTrainingVideo ?? false,
    nextVideo,
    nextSectionWithVideo,
    firstSection,
    getSectionByEncodedSectionName,
    openFreeTraining,
  };
};
