import { IconProps, Icon } from './Icon';

export const BadgeGold = ({ color, ...props }: IconProps) => (
  <Icon {...props} width="174" height="40" viewBox="0 0 174 40" fill="none">
    <path
      d="M0.5 8C0.5 3.85787 3.85786 0.5 8 0.5H166C170.142 0.5 173.5 3.85787 173.5 8V22.0689C173.5 25.8692 170.658 29.0688 166.884 29.5166L87.8838 38.8916C87.2967 38.9613 86.7033 38.9613 86.1162 38.8916L7.11619 29.5167C3.34241 29.0688 0.5 25.8692 0.5 22.0689V8Z"
      fill="white"
    />
    <path
      d="M0.5 8C0.5 3.85787 3.85786 0.5 8 0.5H166C170.142 0.5 173.5 3.85787 173.5 8V22.0689C173.5 25.8692 170.658 29.0688 166.884 29.5166L87.8838 38.8916C87.2967 38.9613 86.7033 38.9613 86.1162 38.8916L7.11619 29.5167C3.34241 29.0688 0.5 25.8692 0.5 22.0689V8Z"
      fill="#EDBA5A"
      fillOpacity="0.2"
    />
    <path
      d="M0.5 8C0.5 3.85787 3.85786 0.5 8 0.5H166C170.142 0.5 173.5 3.85787 173.5 8V22.0689C173.5 25.8692 170.658 29.0688 166.884 29.5166L87.8838 38.8916C87.2967 38.9613 86.7033 38.9613 86.1162 38.8916L7.11619 29.5167C3.34241 29.0688 0.5 25.8692 0.5 22.0689V8Z"
      stroke="#EDBA5A"
    />
  </Icon>
);
