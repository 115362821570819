import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { usePrice } from '../../../hooks/usePrice';
import { PriceInformation } from '../../../models/PriceInformation';
import { useAppSelector } from '../../../store/hooks';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

import { PriceRow } from './PriceRow';

type Props = {
  priceInformation: PriceInformation;
};

export const DiscountCodeRow = ({ priceInformation }: Props) => {
  const t = useTranslations();
  const { priceNumberToString } = usePrice();
  const cart = useAppSelector((state) => state.cart);

  return (
    <PriceRow>
      <DiscountCode>{`${t('discount_code')}: ${cart.discountCode}`}</DiscountCode>
      <DiscountCode>
        {priceNumberToString(priceInformation.discountCodePrice)}
      </DiscountCode>
    </PriceRow>
  );
};

const DiscountCode = styled.h1`
  margin: 0;
  ${TextStyles.PriceMediumText}
  color: ${Colors.discountLabelBackgroundGreen};
`;
