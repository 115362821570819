import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Messages } from '../../../global.d';
import { CourseLevel } from '../../../models/CourseLevel';
import { UserRole } from '../../../models/UserRole';
import { useGetCourseLevelsQuery } from '../../../services/onboardingApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SelectableItem } from '../../common/SelectableItem';
import { SelectableItemContainer } from '../../common/SelectableItemContainer';

type Props = {
  onCourseLevelClick: (courseLevel: CourseLevel) => void;
  selectedCourseLevels: string[];
  selectedRole: UserRole;
};

export const SelectCourseLevelSection = ({
  onCourseLevelClick,
  selectedCourseLevels,
  selectedRole,
}: Props) => {
  const t = useTranslations();
  const { data: courseLevels } = useGetCourseLevelsQuery();

  return (
    <>
      <Header>
        {t(`what_is_your_course_level_${selectedRole}` as keyof Messages)}
      </Header>
      <SelectableItemContainer>
        {courseLevels?.map((courseLevel) => (
          <SelectableItem
            key={courseLevel.name}
            text={courseLevel.name}
            isActive={selectedCourseLevels.includes(courseLevel.name)}
            onClick={() => onCourseLevelClick(courseLevel)}
          />
        ))}
      </SelectableItemContainer>
    </>
  );
};

const Header = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
  padding: 16px 0;
`;
