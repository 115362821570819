import { IconProps, Icon } from './Icon';

export const ChevronRightAlt = ({ color, ...props }: IconProps) => (
  <Icon
    {...props}
    color={color}
    width="8"
    height="24"
    viewBox="0 0 8 24"
    fill="none"
  >
    <path
      d="M2 9L5.82322 12.8232C5.92085 12.9209 5.92085 13.0791 5.82322 13.1768L2 17"
      stroke={color}
      strokeLinecap="round"
    />
  </Icon>
);
