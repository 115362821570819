import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  EMAIL_VERIFICATION_CODE_LENGTH,
  EMAIL_VERIFICATION_COOLDOWN,
} from '../../../constants/constants';
import { HookMediaQueries } from '../../../constants/MediaQueries';
import { appRoutes } from '../../../constants/routes';
import { Messages } from '../../../global.d';
import { useRegister } from '../../../hooks/onboarding/useRegister';
import { useError } from '../../../hooks/useError';
import { useUserManagement } from '../../../hooks/useUserManagement';
import { useResendEmailVerificationMutation } from '../../../services/onboardingApi';
import { useVerifyEmailMutation, Error } from '../../../services/userApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { CardBase, FooterAlignment } from '../../common/CardBase';
import { BigInput } from '../../Inputs/BigInput';
import { CardHeader } from '../CardHeader';

import { EmailVerificationFooter } from './EmailVerificationFooter';
import {
  EmailVerificationSchema,
  FormData,
  FormFields,
} from './EmailVerificationSchema';
import { EmailVerificationState } from './EmailVerificationState';

export const RESEND_PARAM = 'resend';

export const EmailVerificationCard = () => {
  const [emailState, setEmailState] = useState(EmailVerificationState.initial);
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const [resendEmail] = useResendEmailVerificationMutation();
  const { onSuccessfulRegistration } = useRegister();
  const { showErrorModal } = useError();
  const [searchParams] = useSearchParams();

  const userManagementState = useUserManagement();
  const t = useTranslations();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userManagementState.email) {
      navigate(appRoutes.Login);
      return;
    }
    if (searchParams.get(RESEND_PARAM)) {
      sendAgain();
    }
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(EmailVerificationSchema),
    mode: 'onChange',
  });

  const { ref: codeRef, ...codeRegister } = register(FormFields.Code, {
    required: true,
    onChange: (e) => onCodeChange(e.target.value),
  });

  useEffect(() => {
    setTimeout(() => {
      setEmailState(EmailVerificationState.resendable);
    }, EMAIL_VERIFICATION_COOLDOWN);
  }, []);

  const onCodeChange = (code: string) => {
    if (code.length === EMAIL_VERIFICATION_CODE_LENGTH) {
      verify(code);
    }
  };

  const onSubmit = (data: FormData) => verify(data.code);

  const verify = async (code: string) => {
    if (!userManagementState.email) return;
    try {
      const token = await verifyEmail({
        code,
        email: userManagementState.email,
      }).unwrap();

      onSuccessfulRegistration(token);
    } catch (err) {
      handleVerificationError(err as Error);
    }
  };

  const handleVerificationError = (error: Error) => {
    const errorMessages: Record<number, keyof Messages> = {
      400: 'no_valid_code',
      404: 'code_not_found',
      406: 'wrong_code_email_combo',
    };

    const messageKey = errorMessages[error.status];
    if (messageKey) {
      setErrorMessage(messageKey);
    } else {
      showErrorModal(error);
    }
  };

  const sendAgain = async () => {
    if (!userManagementState.email) return;
    setEmailState(EmailVerificationState.resent);

    try {
      await resendEmail({ email: userManagementState.email }).unwrap();

      setEmailState(EmailVerificationState.resent);
      setTimeout(() => {
        setEmailState(EmailVerificationState.resendable);
      }, EMAIL_VERIFICATION_COOLDOWN);
    } catch (err) {
      setEmailState(EmailVerificationState.resendable);
      const error = err as Error;
      showErrorModal(error);
    }
  };

  const setErrorMessage = (messageKey: keyof Messages) => {
    setError(FormFields.Code, {
      message: t(messageKey),
    });
  };

  const getErrorMessage = (error?: FieldError) =>
    error?.message !== undefined ? t(error.message as keyof Messages) : '';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledCardBase
        fullWidth={true}
        header={<CardHeader title={t('validate_email')} />}
        footerButtons={
          <EmailVerificationFooter
            onClick={sendAgain}
            emailState={emailState}
          />
        }
        footerAlignment={FooterAlignment.right}
        fullHeightBody={true}
      >
        <ScrollContainer>
          <Container>
            <Text>{t('validate_email_explanation')}</Text>
            {!isLoading && (
              <BigInput
                type="text"
                errorText={getErrorMessage(errors.code)}
                {...codeRegister}
                inputRef={codeRef}
                maxLength={EMAIL_VERIFICATION_CODE_LENGTH}
              />
            )}
            <Text>
              {t('if_you')} <Highlight>{t('no_code')}</Highlight>
              {t('you_can_try_again')}
            </Text>
          </Container>
        </ScrollContainer>
      </StyledCardBase>
    </form>
  );
};

const StyledCardBase = styled(CardBase)`
  margin: 0;
  height: 100vh;
  overflow-y: auto;

  @media ${HookMediaQueries.isDesktop} {
    margin-top: 24px;
    height: 80vh;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 580px;
  gap: 24px;
`;

const Header = styled.div`
  text-align: center;
`;

const Text = styled.div`
  white-space: pre-line;
  color: ${Colors.inputHintText};
  ${TextStyles.BodyMedium}
  text-align: center;
`;

const Highlight = styled.span`
  color: ${Colors.primary};
`;
