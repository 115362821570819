import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { PrimaryButton } from '../../components/common/Button';
import { CardBase, FooterAlignment } from '../../components/common/CardBase';
import { useAppUpdate } from '../../hooks/useAppUpdate';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

export const ImmediateAppUpdate = () => {
  const t = useTranslations();
  const { startUpdateOrOpenAppStore } = useAppUpdate();

  return (
    <StyledCardBase
      fullWidth={true}
      fullHeightBody={true}
      header={<HeaderText>{t('app_update_available')}</HeaderText>}
      footerAlignment={FooterAlignment.right}
      footerButtons={
        <PrimaryButton
          buttonSize="md"
          aria-label="go-to-store"
          onClick={() => startUpdateOrOpenAppStore(true)}
        >
          {t('go_to_store')}
        </PrimaryButton>
      }
    >
      {t('app_update_available_description')}
    </StyledCardBase>
  );
};

const StyledCardBase = styled(CardBase)`
  margin: 0;
  height: 100vh;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  overflow-y: scroll;
  box-sizing: border-box;
`;

const HeaderText = styled.h1`
  margin: 0;
  text-align: center;
  width: 100%;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
