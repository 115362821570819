import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Messages } from '../../global.d';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { SecondaryButton } from '../common/Button';

type Props = {
  titleTranslationKey: keyof Messages;
  buttonTranslationKey?: keyof Messages;
  onButtonClick?: () => void;
  buttonFullHeight?: boolean;
  titleMarginRight?: number;
};

export const ProfileCardHeader = ({
  titleTranslationKey,
  buttonTranslationKey,
  onButtonClick,
  buttonFullHeight,
  titleMarginRight,
}: Props) => {
  const t = useTranslations();

  return (
    <Container>
      <Title style={{ marginRight: titleMarginRight }}>
        {t(titleTranslationKey)}
      </Title>
      {buttonTranslationKey && (
        <ButtonContainer>
          <StyledSecondaryButton
            buttonSize="sm"
            onClick={onButtonClick}
            buttonFullHeight={buttonFullHeight}
          >
            {t(buttonTranslationKey)}
          </StyledSecondaryButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Title = styled.h1<{ marginRight?: number }>`
  flex: 1;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px;`}
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSecondaryButton = styled(SecondaryButton)<{
  buttonFullHeight?: boolean;
}>`
  height: ${({ buttonFullHeight }) => (buttonFullHeight ? undefined : '30px')};
`;
