import styled from '@emotion/styled';
import { ReactElement, useEffect, useState } from 'react';

import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

import { CardBase, RenderStyle } from './common/CardBase';
import { ChevronRight } from './Icons/ChevronRight';

type VideoActionSubjectCardProps = {
  subjectColor?: string;
  onPress: () => void;
  tagTitle: string;
  backgroundImage?: string;
  iconVariation?: ReactElement;
  highlightedText: string;
  regularText: string;
  tagColor?: string;
};

export const VideoActionSubjectCard = ({
  subjectColor,
  onPress,
  tagTitle,
  backgroundImage,
  iconVariation,
  highlightedText,
  regularText,
  tagColor = Colors.tagColor,
  ...props
}: VideoActionSubjectCardProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = () => setImageLoaded(true);
      img.src = backgroundImage;

      // Reset the state when backgroundImage changes
      return () => setImageLoaded(false);
    }
  }, [backgroundImage]);

  return (
    <Button
      onClick={onPress}
      bgColor={subjectColor}
      renderStyle={RenderStyle.desktop}
      {...props}
    >
      {backgroundImage && (
        <BackgroundImage
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        />
      )}
      <Container showOverlay={imageLoaded}>
        <ItemContainer>
          <ExoTag bgColor={tagColor}>
            {iconVariation} {tagTitle}
          </ExoTag>

          <TextContainer>
            <ExplanationVideoText textColor={tagColor}>
              {highlightedText}
            </ExplanationVideoText>
            <ExplanationVideoText>{regularText}</ExplanationVideoText>
          </TextContainer>
        </ItemContainer>
        <ItemContainer>
          <ChevronRight color={Colors.primaryButtonContentColor} />
        </ItemContainer>
      </Container>
    </Button>
  );
};

const Button = styled(CardBase)<{ bgColor?: string }>`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.bgColor};
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  justify-content: center;
  margin-top: 0;
`;

const ExoTag = styled.div<{ bgColor?: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  background-color: ${(props) => props.bgColor || Colors.tagColor};
  padding: 2px 6px;
  border-radius: 4px;
  position: relative;
  background-color: ${(props) => props.bgColor || Colors.tagColor};
  color: ${Colors.primaryButtonContentColor};
  ${TextStyles.DiscountLabelText}
`;

const ExplanationVideoText = styled.span<{ textColor?: string }>`
  color: ${(props) => props.textColor ?? Colors.primaryButtonContentColor};
  font-weight: ${(props) => props.textColor && 700};
  ${TextStyles.ExplanationVideoText}
  position: relative;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const BackgroundImage = styled.div`
  position: absolute;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(8px);
`;

const Container = styled.div<{ showOverlay: boolean }>`
  position: absolute;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background-color: ${(props) =>
    props.showOverlay ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
