import { ProductTypeCount } from '../../models/onboarding/ProductTypeCount';
import { ProductBundle } from '../../models/ProductBundle';

/**
 * Hook that calculates the count of each product type in a list of product bundles
 * @param productBundles - Array of product bundles to analyze
 * @returns Array of product type counts with their descriptions
 */
export const useAmountOfProductTypesInProductBundles = (
  productBundles: ProductBundle[]
): ProductTypeCount[] => {
  // Count occurrences of each product type description
  const productTypeCounts: Record<string, number> = {};

  for (const bundle of productBundles) {
    const productType = bundle.description;
    productTypeCounts[productType] = (productTypeCounts[productType] || 0) + 1;
  }

  // Transform the counts into the required ProductTypeCount format
  return Object.entries(productTypeCounts).map(([description, amount]) => ({
    description,
    amount,
  }));
};
