export const Colors = {
  primary: '#EC671B',
  primaryNavbarBackgroundColor: 'white',
  primaryBackgroundColor: '#f4f4f4',
  primaryButtonFill: '#ec671b',
  primaryButtonHover: '#d24e02',
  primaryButtonStroke: '#d24e02',
  primaryButtonContentColor: '#ffffff',
  primaryButtonContentColorDisabled: 'rgba(255, 255, 255, 0.4)',
  primaryButtonIcon: '#ffffff',
  secondaryButtonFill: '#eeeeee',
  secondaryButtonHover: '#c3c3c3',
  secondaryButtonStroke: '#c3c3c3',
  secondaryButtonContentColor: 'rgba(33, 37, 25, 1)',
  secondaryButtonContentColorDisabled: 'rgba(33, 37, 25, 0.4)',
  appleButtonFill: '#333333',
  appleButtonHover: '#000000',
  appleButtonStroke: '#000000',
  appleButtonContentColor: '#ffffff',
  appleButtonContentColorDisabled: 'rgba(255, 255, 255, 0.4)',
  facebookButtonFill: '#0866FF',
  facebookButtonHover: '#004AC3',
  facebookButtonStroke: '#004AC3',
  facebookButtonContentColor: '#ffffff',
  facebookButtonContentColorDisabled: 'rgba(255, 255, 255, 0.4)',
  googleButtonFill: '#EA4335',
  googleButtonHover: '#B93524',
  googleButtonStroke: '#B93524',
  googleButtonContentColor: '#ffffff',
  googleButtonContentColorDisabled: 'rgba(255, 255, 255, 0.4)',
  errorButtonFill: '#F42B27',
  errorButtonHover: '#B93524',
  errorButtonStroke: '#B93524',
  errorButtonContentColor: '#ffffff',
  errorButtonContentColorDisabled: 'rgba(255, 255, 255, 0.4)',
  shadowColor: 'rgba(0, 0, 0, 0.08)',
  primaryTextColor: '#333333',
  primaryTextInverted: '#FFFFFF',
  modalBackgroundColor: '#ffffff',
  dividerColor: '#e8e8e8',
  topicNegative: '#f94040',
  textNormal: '#212519',
  topicPositive: '#2ab966',
  topicMedium: '#fed702',
  inputTextColor: '#212519',
  inputBackgroundColor: '#f9f9f9',
  inputBorderColor: '#e8e8e8',
  secondaryTextColor: '#666666',
  primaryIconColor: '#333333',
  inputHintText: '#333333',
  disabledContentColor: '#a9a9a9',
  backgroundColor: '#f9f9f9',
  discountLabelContent: '#ec671b',
  discountLabelBackground: '#ec671b',
  progressBarDefault: '#e8e8e8',
  progressBarHighlighted: '#ec671b',
  disabledTextColor: '#adadad',
  inputHintError: '#f42b27',
  errorColor: '#F42B27',
  outlineBackgroundButton: '#e7e7e7',
  navbarBoxShadow: 'rgba(34, 34, 34, 0.25)',
  textLessGrade: '#ff7f7f',
  tagColor: '#FAA500',
  forgotLinkTextColor: '#000000',
  subjectTextColor: '#000000',
  popupBackgroundColor: '#00000080',
  summaryBackgroundColor: '#ffffff',
  modalBackdrop: '#000000',
  radioColor: '#D9D9D9',
  listItemSelected: 'rgba(236, 103, 27, 0.08)',
  radioInactive: '#A9A9A9',
  linkTextSecondary: '#007BFF',
  leaderBoardFirst: '#ec671b',
  leaderBoardSecond: '#f38d65',
  leaderBoardThird: '#bababa',
  calendarEventText: '#ffffff',
  subjectRowHover: '#dcdcdc',
  discountPercentageBackground: '#ec671b14',
  discountPercentageText: '#ec671b',
  discountLabelBackgroundGreen: '#0DB78E',
  gold: '#EDBA5A',
  goldText: '#805E00',
  goldBorder: '#EDBA5A',
  silver: '#EFEFEF',
  silverText: '#717171',
  silverBorder: '#D7D7D7',
  bronze: '#FCC299',
  bronzeText: '#8F571F',
  bronzeBorder: '#B97C3F',
  examTrainingColor: '#0DB78E',
};
