import { ApiTagTypes } from '../constants/api';
import { baseApi } from '../services/baseApi';
import { useAppDispatch } from '../store/hooks';

export const useInvalidateTags = () => {
  const dispatch = useAppDispatch();

  const invalidateTags = (tags: ApiTagTypes[]) => {
    // The docs specify that invalidateTags accepts a string[], for example: dispatch(api.util.invalidateTags(['Post']))
    // But the type definition is broken, so we need to cast it to any[]
    dispatch(baseApi.util.invalidateTags(tags as any[]));
  };

  return invalidateTags;
};
