import styled from '@emotion/styled';

import { ProductTypeCount } from '../../../models/onboarding/ProductTypeCount';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { Checkmark } from '../../Icons/Checkmark';
import { ChevronRightAlt } from '../../Icons/ChevronRightAlt';

type Props = {
  productType: ProductTypeCount;
  handleProductTypeClick: (productType: string) => void;
};

export const ProductTypeRow = ({
  productType,
  handleProductTypeClick,
}: Props) => {
  return (
    <ProductType
      key={productType.description}
      onClick={() => handleProductTypeClick(productType.description)}
    >
      <Checkmark color={Colors.primary} />
      <ProductTypeAmount>{productType.amount}x</ProductTypeAmount>
      <ProductTypeDescription>{productType.description}</ProductTypeDescription>
      <ChevronRightAlt color={Colors.primaryIconColor} />
    </ProductType>
  );
};

const ProductType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ProductTypeAmount = styled.h1`
  margin: 0;
  padding-left: 8px;
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleExtraSmall}
`;

const ProductTypeDescription = styled.h1`
  margin: 0;
  padding-left: 4px;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.BodySmall}
`;
