import styled from '@emotion/styled';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { LockIcon } from '../../Icons/LockIcon';

type Props = {
  lockedMessage: string;
};

export const Locked = ({ lockedMessage }: Props) => {
  return (
    <LockContainer>
      <StyledLockIcon color={Colors.disabledContentColor} />
      <LockedText>{lockedMessage}</LockedText>
    </LockContainer>
  );
};

const LockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 100%;
`;

const StyledLockIcon = styled(LockIcon)`
  height: 80px;
  width: 80px;
`;

const LockedText = styled.h1`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.BodyText};
  text-align: center;
`;
