import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { FREE_SHIPPING_REQUIREMENT_PRICE } from '../../../constants/constants';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

import { PriceRow } from './PriceRow';

export const DeliveryMessageRow = () => {
  const t = useTranslations();

  return (
    <PriceRow>
      <Text>{`${t('free_postage')} ${FREE_SHIPPING_REQUIREMENT_PRICE}`}</Text>
    </PriceRow>
  );
};

const Text = styled.h1`
  margin: 0;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;
